/* global config */

import axios from "axios";
require("dotenv/config");

console.log(config.connection.backend_url);
const api = axios.create({
  baseURL: config.connection.backend_url,
});

export default api;
