import React from 'react';
import { Upload, Icon, message } from 'antd';
import './MyAccountConfigAvatar.css'
import api from '../../services/api';
require('dotenv/config');

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export class MyAccountConfigAvatar extends React.Component {
    state = {
        loading: false,
        fileList: [],
    };

    handleChange = ({fileList}) => {
        console.log('fileList', fileList[0]);
  
        // you store them in state, so that you can make a http req with them later
        this.setState({ fileList });

        setTimeout(() => this.handlerUpload(), 1000);

    };

    handlerUpload = () => {

        // Get this url from response in real world.
        getBase64(this.state.fileList[0].originFileObj, async (imageUrl) => {
            
            const response = await api.put("/users/update/avatar", {
                file: imageUrl
            },{ headers: { 'Authorization': 'Bearer ' + localStorage.getItem('v4h_token') } });
    
            console.log('recebido: ', response.data);
            
            this.setState({
                imageUrl: response.data.file,
                loading: false,
            });
        });
        
    }


    test = () => {
        console.log(this.state.imageUrl);
    }


    render() {

        const uploadButton = (
            <div>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">Upload</div>
            </div>
          );
        const { fileList, imageUrl } = this.state;

        return(
            <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={ () => false }
            fileList=''
            onChange={this.handleChange}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', borderRadius: '100px' }} /> : uploadButton}
            </Upload>
          );
    }

}