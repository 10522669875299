import React from "react";
import {
  notification,
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Empty,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Spin,
  Tooltip,
} from "antd";
import "./AddButton.css";
import api from "../../services/api";

const { RangePicker } = DatePicker;
const { Option } = Select;

export class AddButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      title: "",
      scheduleDate: new Date(),
      collaborators: [],
      startTime: "",
      finishTime: "",
      description: "",
      loading: "",
      typeConference: "conference",
    };

    this.children = [];
    this.collaboratorsUuid = [];
    this.collaboratorsEmail = [];
  }

  async componentDidMount() {
    const response = await api.get("/companies/users", {
      headers: { Authorization: "Bearer " + localStorage.getItem("v4h_token") },
    });
    console.log(response.data);

    for (var x in response.data) {
      if (response.data[x].uuid == localStorage.getItem("v4h_uuid")) {
        continue;
      }
      this.children.push(
        <Option key={[response.data[x].uuid, response.data[x].email]}>
          {response.data[x].email}
        </Option>
      );
    }
  }

  showDrawer = () => {
    this.setState(
      {
        visible: true,
      },
      () => {
        setTimeout(() => {
          this.testInput && this.testInput.focus();
        }, 1);
      }
    );
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  handleTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  handleTypeConference = (value) => {
    this.setState({
      typeConference: value,
    });
  };

  handleCollaborators = (value) => {
    this.setState({ collaborators: value });
    setTimeout(() => console.log(this.state.collaborators), 1000);
  };

  handleDate = (e) => {
    console.log("date: ", e);
    const year = e._d.getFullYear();
    const month = this.formatHour(e._d.getMonth() + 1);
    const day = this.formatHour(e._d.getDate());
    const hours = String(e._d).substring(16, 24);
    this.setState({
      scheduleDate: year + "-" + month + "-" + day + " " + hours,
    });
  };

  formatHour = (value) => {
    if (String(value).length == 1) {
      return "0" + value;
    }
    return value;
  };

  handleStartTime = (e) => {
    this.setState({
      startTime: String(e._d).substring(16, 21),
    });
  };

  handleFinishTime = (e) => {
    this.setState({
      finishTime: String(e._d).substring(16, 21),
    });
  };

  handleDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  adjustParticipantData = async () => {
    //adjustment in participants.
    for (var i = 0; i < this.state.collaborators.length; ) {
      const uuid_email = this.state.collaborators.pop().split(",");
      console.log(i);
      console.log(this.state.collaborators);
      console.log(uuid_email[0], uuid_email[1]);
      this.collaboratorsUuid.push(uuid_email[0]);
      this.collaboratorsEmail.push(uuid_email[1]);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    await this.adjustParticipantData();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
      console.log(values);
      if (values.timestart.isAfter(values["timeend"])) {
        this.props.form.setFields({
          timeend: {
            value: values.timeend,
            errors: [new Error("Hora de fim menor")],
          },
        });
      }
    });

    try {
      const response = await api.post(
        "/videosession",
        {
          title: this.state.title,
          description: this.state.description,
          scheduleDate: this.state.scheduleDate,
          startTime: this.state.startTime,
          finishTime: this.state.finishTime,
          collaborators: this.collaboratorsUuid,
          collaboratorsEmail: this.collaboratorsEmail,
          type: this.state.typeConference,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("v4h_token"),
          },
        }
      );
      console.log(response);
      this.onClose();
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "success",
        "Sucesso.",
        "Agendamendo realizado com Sucesso."
      );
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } catch (error) {
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "error",
        "Falha ao agendar videoconferência.",
        "Houve um problema ao processar o agendamento, tente novamente."
      );
    }
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="addDropdown">
        <Tooltip placement="top" title={"Criar sessão"}>
          <button className="addButton" onClick={this.showDrawer}>
            <span>+</span>
          </button>
        </Tooltip>
        <Drawer
          title="Adicionar uma nova sessão"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Spin spinning={this.state.loading}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={17}>
                  <Form.Item label="Título">
                    {getFieldDecorator("title", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, adicione o título",
                        },
                      ],
                    })(
                      <Input
                        ref={(input) => {
                          this.testInput = input;
                        }}
                        placeholder="Adicione o título"
                        onChange={this.handleTitle}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="Tipo da Sessão">
                    <Select
                      defaultValue="class"
                      onChange={this.handleTypeConference}
                    >
                      <Option value="conference">Conferência</Option>
                      <Option value="class">Aula</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Participantes">
                    {getFieldDecorator("participants", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, selecione os Participantes",
                        },
                      ],
                    })(
                      <Select
                        notFoundContent={<Empty description={"Sem Dados"} />}
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Selecione os Participantes"
                        onChange={this.handleCollaborators}
                      >
                        {this.children}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Data">
                    {getFieldDecorator("date", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha a data",
                        },
                      ],
                    })(
                      <DatePicker
                        size={"default"}
                        placeholder="Selecione a Data"
                        onChange={this.handleDate}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Hora">
                    {getFieldDecorator("timestart", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha o horário de início",
                        },
                      ],
                    })(
                      <TimePicker
                        format={"HH:mm"}
                        placeholder="Início"
                        onChange={this.handleStartTime}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    {getFieldDecorator("timeend", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha o horário de término",
                        },
                      ],
                    })(
                      <TimePicker
                        format={"HH:mm"}
                        style={{ marginTop: "28px" }}
                        placeholder="Fim"
                        onChange={this.handleFinishTime}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Descrição">
                    {getFieldDecorator(
                      "description",
                      {}
                    )(
                      <Input.TextArea
                        rows={4}
                        placeholder="Adicione uma descrição"
                        onChange={this.handleDescription}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div className="typeMsg">
              <h3>Observação</h3>
              {this.state.typeConference == "conference" ? (
                <p>
                  <strong>Conferência: </strong> neste modo os visitantes podem
                  configurar como eles querem entrar na conferência.
                </p>
              ) : (
                <p>
                  <strong>Aula: </strong> Os visitantes entram na conferência
                  com a imagem(câmera) e audio(microfone) desligados.
                </p>
              )}
            </div>
          </Spin>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancelar
            </Button>
            <Button onClick={this.handleSubmit} htmlType="submit" type="submit">
              Concluir
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}
