import React from 'react';
import { Result, Button } from 'antd';

export function ResultError({ props, history }) {
    
    function goBack() {
        history.goBack();
    }

    function tryAgain() {
        document.location.reload(false);
    }

    return(
        <Result
            status="500"
            title="Falha ao iniciar conferência."
            subTitle="Por favor, tente novamente mais tarde."
            extra={[
            <Button type="primary" key="console" onClick={goBack}>
                Voltar
            </Button>,
            <Button key="buy" onClick={tryAgain} >Tentar Novamente</Button>,
            ]}
        ></Result>
    );
}