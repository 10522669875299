import React from "react";
import { MenuV4h, MenuV4hSider } from "../../compoments/menu";
import { ListRecordedSessions } from "../../compoments/listRecordedSessions";
import "../../compoments/listRecordedSessions/FilterButton";
import "./Session.css";
import { Layout, Row, Col, Icon } from "antd";
import { ConferenceAPI } from "../../services";
import { AppBar } from "../../compoments/common/topBar";
import moment from "moment";
import { HeaderFilter } from "../../compoments/listRecordedSessions/listHeader";

//const FilterB = Form.create()(FilterButton);

class Session extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      title: "",
      host: "",
      data: [],
      time: [],
      timeInterval: [moment(), moment()],
    };
  }

  async componentDidMount() {
    try {
      let response = await ConferenceAPI.getScheduledConferences(
        localStorage.getItem("v4h_token")
      );
      console.log("recebi do servifor: ", response);

      // sort the data
      response = response.sort((a, b) => {
        if (a.start_time < b.start_time) return 1;
        else return -1;
      });

      let first = response[0].start_time;
      let last = response[response.length - 1].start_time;

      console.log("MOMENT: ", first, last);

      this.setState({
        data: response,
        time: [last, first],
        timeInterval: [last, first],
      });
    } catch (error) {
      console.log("error!! <>", error);
    }
  }

  checkMenu = () => {
    if (localStorage.getItem("v4h_menu") === "MenuV4h") {
      return (
        <MenuV4h
          history={this.props.history}
          menu_theme={localStorage.getItem("v4h_settings_menu_theme")}
        />
      );
    } else {
      return (
        <MenuV4hSider
          history={this.props.history}
          menu_theme={localStorage.getItem("v4h_settings_menu_theme")}
        />
      );
    }
  };

  handleFilters = (id, title, host, date, timeStart, timeEnd) => {
    this.setState({
      id,
      title,
      host,
      date,
      timeStart,
      timeEnd,
    });
  };

  handleDate = (initDate, endDate, numPags) => {};

  handlePage = (page) => {};

  /**
   * Change the state.
   *
   * @param {Array} time
   * @param {Array} filteredData
   *
   * @return {void}
   */
  setfiltereddata = (startTime, endTime) => {
    this.setState({
      timeInterval: [startTime, endTime],
    });
    console.log("setTime");
  };

  render() {
    return (
      <Layout className="sessionContainer">
        {this.checkMenu()}
        <div className="sessionBody">
          <Row className="appbar">
            <Col span={24}>
              <AppBar title="Sessões" />
            </Col>
          </Row>

          <Row className="sessions">
            <div className="sessionList">
              <div className="sessionButton">
                <div className="leftButtonContainer">
                  <div style={{ width: "19px" }}></div>
                  <HeaderFilter
                    data={this.data}
                    setfiltereddata={this.setfiltereddata}
                    date={this.state.timeInterval}
                    time={this.state.time}
                  />
                </div>
                <div
                  className="rightButtonContainer"
                  style={{ display: "none  " }}
                >
                  {/* <FilterB updateFilters={this.handleFilters} /> */}
                  <button className="pagButton">
                    <Icon
                      className="iconButton"
                      style={{ fontSize: "20px" }}
                      type="search"
                    />
                  </button>
                </div>
              </div>
              <ListRecordedSessions
                id={this.state.id}
                title={this.state.title}
                host={this.state.host}
                data={this.state.data}
                timeInterval={this.state.timeInterval}
                updateDate={this.handleDate}
                updatePage={this.handlePage}
              />
            </div>
          </Row>
        </div>
      </Layout>
    );
  }
}

export default Session;
