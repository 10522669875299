import React, { useState, useEffect } from "react";
import "./Register.css";
import { RegisterUser, RegisterCompany } from "../../compoments/register";

import logo from "../../assets/v4h/login-logo.png";

export function Register({ history, match }) {
  const [modoCadastro, setModoCadastro] = useState(1);

  useEffect(() => {
    if (!match.params.active) history.push("/");
  });

  function handlerClickBackButton(e) {
    e.preventDefault();
    history.push("/cadastro");
  }

  function handlerModoCadastro() {
    if (modoCadastro) {
      setModoCadastro(0);
    } else {
      setModoCadastro(1);
    }
  }

  function RegisterCond(props) {
    if (props.state) {
      return (
        <RegisterUser
          modoCadastro={handlerModoCadastro.bind(this)}
          history={history}
        />
      );
    }

    return (
      <RegisterCompany
        modoCadastro={handlerModoCadastro.bind(this)}
        history={history}
      />
    );
  }

  return (
    <div className="registerContainer">
      <div className="backButtonContainer" onClick={handlerClickBackButton}>
        <svg className="backButtonSvg">
          <rect className="backButtonRect" />
        </svg>
        <div className="backButtonText">Voltar</div>
      </div>

      <div className="registerForm-Container">
        <div className="registerForm">
          <div className="registerFormHeader">
            <img src={logo} alt="v4h" />
          </div>

          <div className="registerFormBar"></div>

          <div>
            <RegisterCond state={modoCadastro} />
          </div>
        </div>
      </div>
    </div>
  );
}
