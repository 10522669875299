import React, { useState, useEffect } from "react";
import { Modal, Icon } from "antd";
import "./ActionsButton.css";
import api from "../../services/api";
import ReactPlayer from "react-player";
import { SessionAPI, ConferenceAPI } from "../../services";

export function ActionsButton(props) {
  let uuidSession = props.uuidSession;
  const [modalVisible, setModalVisible] = useState(false);
  const [sessionDetails, setSessionDetails] = useState("");
  const [btnDownloadVisible, setBtnDownloadVisible] = useState(false);
  const [btnPlayVisible, setBtnPlayVisible] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    setSessionDetails("");
    setUrl("");
    setBtnDownloadVisible(false);
    setBtnPlayVisible(false);

    async function getSession() {
      const session = await ConferenceAPI.readSession(
        uuidSession,
        localStorage.getItem("v4h_token")
      );

      if (session["storageToken"] !== "[]" && session["storageToken"] !== "") {
        session["storageToken"] = JSON.parse(session["storageToken"])[0];
        setBtnPlayVisible(true);

        if (session["key"] === "[]" && session["key"] !== "") {
          setBtnDownloadVisible(true);
          setBtnPlayVisible(false);
        }
      }

      setSessionDetails(session);
    }

    getSession();
  }, [uuidSession]);

  function closeModal() {
    setModalVisible(false);
  }

  function HandlerPlayButton() {
    Play();
    setModalVisible(true);
  }

  function Download() {
    console.log(sessionDetails["storageToken"]);
    SessionAPI.donwloadSession(
      sessionDetails["storageToken"],
      localStorage.getItem("v4h_token")
    );
  }

  function Play() {
    const urlVideo = SessionAPI.getRecordSession(
      uuidSession,
      localStorage.getItem("v4h_token")
    );
    setUrl(urlVideo);
  }

  return (
    <div className="ActionsButton">
      <div className={"playButton"}>
        <button
          className={btnDownloadVisible ? "" : "disabled"}
          onClick={Download}
        >
          <div>
            <Icon type="download" />
            <span>Download</span>
          </div>
        </button>
        <button
          onClick={HandlerPlayButton}
          className={
            btnPlayVisible ? "" : btnDownloadVisible ? "disabled" : "disable"
          }
        >
          {btnPlayVisible ? (
            <div>
              <Icon type="play-square" />
              <span>Vídeo</span>
            </div>
          ) : (
            <span>Sem Gravação</span>
          )}
        </button>
      </div>
      <Modal
        title={`Vídeo gravado da sessão ${uuidSession}`}
        centered
        visible={modalVisible}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        closable={true}
        keyboard={false}
        maskClosable={false}
        footer={null}
        height="60%"
        width="60%"
      >
        <div>
          <ReactPlayer url={url} controls playing width="100%" height="100%" />
        </div>
      </Modal>
    </div>
  );
}
