import React from "react";
import { Progress } from "antd";
import { allowedKeys } from "../../../modules/keyboard/map";

export class ProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.regularExpressionWeakPassword = new RegExp(
      `^[0-9a-zA-Z${allowedKeys.join("")}]{6,}$`
    );
    this.regularExpressionMediumPassword = new RegExp(
      `^[0-9a-zA-Z${allowedKeys.join(
        ""
      )}](?=.*[a-z])[0-9a-zA-Z${allowedKeys.join("")}]{8,}$`
    );
    this.regularExpressionStrongPassword = new RegExp(
      `^[0-9a-zA-Z${allowedKeys.join(
        ""
      )}](?=.*[a-z])(?=.*[A-Z])(?=.*[${allowedKeys.join(
        ""
      )}])[0-9a-zA-Z${allowedKeys.join("")}]{8,}$`
    );
  }

  testPassword = () => {
    if (this.regularExpressionStrongPassword.test(this.props.pass)) {
      return 100;
    } else if (this.regularExpressionMediumPassword.test(this.props.pass)) {
      return 66.6;
    } else if (this.regularExpressionWeakPassword.test(this.props.pass)) {
      return 33.3;
    } else {
      return 0;
    }
  };

  render() {
    return (
      <Progress
        strokeColor={{
          from: "#52C8FA",
          to: "#87d068",
        }}
        showInfo={false}
        percent={this.testPassword()}
        status="active"
        strokeWidth="5px"
      />
    );
  }
}
