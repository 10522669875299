/* global config */

import React from "react";
import "./index.css";
require("dotenv/config");

export default function Mobile({ history }) {
  let token;
  let sessionId;

  try {
    token = history.location.state.token;
    sessionId = history.location.state.sessionId;
  } catch {
    history.push("/");
  }

  const iOS = () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  };

  // Plataform IOS
  const iOSRender = () => {
    return (
      <div id="bodyMobile">
        <div className="containerMobile">
          <div className="headerMobile">
            <h1>V4H - Video para Saúde</h1>
          </div>
          <div>
            <p>
              Caso já tenha o app instalado, clique no botão abaixo para acessar
              a conferência:
            </p>
          </div>
          <div className="buttonMobile">
            <a
              className="linkConferenceIOS"
              href={`conf.v4h.cloud://${config.connection.conf_url}/${sessionId}?jwt=${token}`}
            >
              Ir para a conferência
            </a>
          </div>
          <div className="tutorialMobile">
            <p>
              Caso não tenha o aplicativo instalado, siga as instruções abaixo:
            </p>
            <ul>
              <li>1. Instale o aplicativo v4h na apple store.</li>
              <li>
                2. Após instalar o aplicativo, clique no link de acesso da
                conferência.
              </li>
              <li>3. Pronto, você será encaminhado para a conferência.</li>
            </ul>
          </div>
          <div className="buttonMobile">
            <a
              className="linkConferenceIOS"
              href="https://apps.apple.com/br/app/id1504981384"
            >
              Instalar App
            </a>
          </div>
        </div>
        <div className="footerMobile">
          <img src={require("../../assets/v4h/logo2.png")} />
        </div>
      </div>
    );
  };

  // Plataform Android
  const AndroidRender = () => {
    return (
      <div id="bodyMobile">
        <div className="containerMobile">
          <div className="headerMobile">
            <h1>V4H - Video para Saúde</h1>
          </div>
          <div>
            <p>
              Caso já tenha o app instalado, clique no botão abaixo para acessar
              a conferência:
            </p>
          </div>
          <div className="buttonMobile">
            <a
              className="linkConferenceAndroid"
              href={`https://${config.connection.conf_url}/${sessionId}?jwt=${token}`}
            >
              Ir para a conferência
            </a>
          </div>
          <div className="tutorialMobile">
            <p>
              Caso não tenha o aplicativo instalado, siga as instruções abaixo:
            </p>
            <ul>
              <li>1. Instale o aplicativo v4h na play store.</li>
              <li>
                2. Após instalar o aplicativo, clique no link de acesso da
                conferência.
              </li>
              <li>3. Pronto, você será encaminhado para a conferência.</li>
            </ul>
          </div>
          <div className="buttonMobile">
            <a
              className="linkConferenceAndroid"
              href="https://play.google.com/store/apps/details?id=lavid.conf.v4h.cloud"
            >
              Instalar App
            </a>
          </div>
          <div className="tutorialMobile">
            <p>
              Se você ainda tem dúvidas sobre como realizar o processo de
              instalação do aplicativo descrito acima, temos um video abaixo que
              pode lhe ajudar.
            </p>
          </div>
          <div className="videoMobile">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/X3PLSLtijhA"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="footerMobile">
          <img src={require("../../assets/v4h/logo2.png")} />
        </div>
      </div>
    );
  };

  return iOS() ? iOSRender() : AndroidRender();
}

/***
 * IOS Model
conf.v4h.cloud://aws.v4h.cloud/91144d839a0340e8aa88a8523460045a?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnsibmFtZSI6Ikx1Y2FzIn19LCJhdWQiOiJ2NGhfYXBwIiwiaXNzIjoidjRoX2FwcCIsInN1YiI6InY0aC1uZXQiLCJyb29tIjoiKiIsImlhdCI6MTU4NTg2MjE0MH0.dHqZhUU9EG12AtLZcQbAJQ1kZCWe6tDSZ_pM1zFbWTA
 */

/**
  * Android Model
https://conf.v4h.cloud/159d6473f48142d8ab76695dd429accf?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnsibmFtZSI6Ikx1Y2FzIn19LCJhdWQiOiJ2NGhfYXBwIiwiaXNzIjoidjRoX2FwcCIsInN1YiI6InY0aC1uZXQiLCJyb29tIjoiKiIsImlhdCI6MTU4NTk0Mzc5Nn0.x_GNXaUgbGvg3nlpdVPF0eXMIAdVY1N_uL_oOoIK2LI
  */
