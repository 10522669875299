/* global config */

import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { ResultError } from "../../compoments/resultMsg";
import "./QuickConference.css";
import { Spin } from "antd";
import { ConferenceAPI } from "../../services";

export function QuickConference({ match, history }) {
  const [error, setError] = useState([]);

  let v4h_ss_domain = `${config.connection.conf_url}/session-service`;

  /* Settings for session-service API */
  let coreapi = window.coreapi;
  let schema = window.schema;
  let loggedIn = false;
  let node = {};
  let jApi = {};
  let sessionId = "";
  let token = { access: "", refresh: "" };
  let client = new window.coreapi.Client(v4h_ss_domain);
  let EndedListener;
  let uuidSession = "";
  let shortLink;
  let locate = "pt-BR";
  let locateUSA = "en-us";

  /* Initial conference settings */
  let confName = history.location.state.confName;
  let settingsCam = history.location.state.camStatus;
  let settingsMic = history.location.state.microphoneStatus;
  let userName =
    localStorage.getItem("v4h_user_firstName") +
    " " +
    localStorage.getItem("v4h_user_lastName");

  useEffect(() => {
    async function HandlerstartVideoConfNow() {
      const date = new Date();

      try {
        const sessionRequestId = await api.post(
          "/videosession",
          {
            title: confName,
            scheduleDate: date.toLocaleDateString(locateUSA),
            startTime: date.toLocaleTimeString(locate),
            collaborators: [],
            description: "Conferência rápida",
            type: "conference",
            state: 2,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("v4h_token"),
            },
          }
        );

        console.log("Session Id request - ", sessionRequestId);
        uuidSession = sessionRequestId.data.uuid;

        const response = await api.post("/videosession/login", null, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("v4h_token"),
          },
        });
        token.access = response.data.access;
        token.refresh = response.data.refresh;

        const tokenJwt = await api.get(
          `/videosession/getLinkAccess/${sessionRequestId.data.uuid
            .split("-")
            .join("")}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("v4h_token"),
            },
          }
        );
        shortLink = tokenJwt.data.shortLink;

        await loginWithToken(token.access);

        await startConference(
          sessionRequestId.data.uuid.split("-").join(""),
          document.querySelector("#meet"),
          "100%",
          "100%"
        );
        document.getElementById("spin").remove();
      } catch (err) {
        setError(<ResultError history={history} />);
      }
    }
    HandlerstartVideoConfNow();
  }, []);

  function conferenceEnded() {
    document.getElementById("meet").innerHTML = "";

    history.push("/ScheduleAppointment");
  }

  function loginWithToken(access_token) {
    return new Promise(function (resolve, reject) {
      let action = ["api", "token", "verify", "create"];
      let params = { token: access_token };
      client
        .action(schema, action, params)
        .then(function (result) {
          let auth = new coreapi.auth.TokenAuthentication({
            token: access_token,
          });
          client = new coreapi.Client({ auth: auth });
          loggedIn = true;
          token["access"] = access_token;
          resolve(token);
        })
        .catch(function (error) {
          console.error(error);
          loggedIn = false;
          reject(error);
        });
    });
  }

  function startConference(sessionId, parentNode, width, height) {
    return new Promise(function (resolve, reject) {
      node = parentNode;
      let action = ["conferences", "update"];
      let params = { sessionId: sessionId, conf_state: 1 };
      client
        .action(schema, action, params)
        .then(function (result) {
          sessionId = result["sessionId"];
          console.log("testteeee ##", result);
          console.log(
            "V4H-rest-cli-api 1:: starting conf on room " + sessionId
          );
          _openJitsi(sessionId, result["conf_auth"], parentNode, width, height);
          resolve(sessionId);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    });
  }

  function _endedConference(sessionId) {
    let action = ["conferences", "update"];
    let params = { sessionId: "", conf_state: 3 };
    client.action(schema, action, params).then(function (result) {
      console.log(
        "V4H-rest-cli-api :: updated conf " +
          result["sessionId"] +
          " conf_state to " +
          result["conf_state"]
      );
    });
    conferenceEnded();
  }

  async function _openJitsi(sessionId, jwt, parentNode, width, height) {
    width = width || 640;
    height = height || 480;
    const domain = config.connection.conf_url;
    const options = {
      roomName: sessionId,
      width: width,
      height: height,
      noSsl: false,
      parentNode: node,
      jwt: jwt,
    };

    jApi = new window.JitsiMeetExternalAPI(domain, options);

    /* audio and camera settings of conference */
    jApi.executeCommand("displayName", `${userName}`);
    if (!settingsCam) {
      jApi.executeCommand("toggleVideo");
    }
    if (!settingsMic) {
      jApi.executeCommand("toggleAudio");
    }

    jApi.addEventListener("readyToClose", () => _endedConference(sessionId));

    jApi.addEventListener("audioMuteStatusChanged", (response) => {
      console.log(`UUID:${uuidSession}`);
      if (response.muted == true) {
        console.log("Desligou o microfone!");
      } else {
        console.log("Ligou o microfone!");
      }
    });

    /** Link to share **/
    jApi.executeCommand("link", shortLink);
  }

  return (
    <div className="quickConference-container">
      <div id="meet">
        <div id="spin">
          <Spin size="large" />
        </div>
      </div>
    </div>
  );
}
