import React from 'react';
import { Form, Button, Col, Row, Input, Dropdown, Icon, DatePicker, TimePicker, Empty, Select, Popover } from 'antd';
import './FilterButton.css'
import $ from 'jquery';

const { RangePicker } = DatePicker;
const Option = Select.Option;

export class FilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      id: '',
      title: '',
      host: '',
      participants: '',
      date: null,
      startTime: null,
      finishTime: null,
    };
  }

  handleId = (e) => {
    console.log(e.target.value);
    this.setState({
      id: e.target.value
    });
  }

  handleTitle = (e) => {
    this.setState({
      title: e.target.value
    });
  }

  handleHost = (e) => {
    console.log(e)
    this.setState({
      host: e.target.value
    });
  }

  handleParticipants = (e) => {
    this.setState({
      participants: e.target.value
    });
  }

  handleDate = (e) => {
    this.setState({
      date: e
    });
  }

  handleStartTime = (e) => {
    this.setState({
      startTime: e
    });
  }

  handleFinishTime = (e) => {
    this.setState({
      finishTime: e
    });
  }

  handleClear = () => {
    this.setState({
      id: '',
      title: '',
      host: '',
      participants: '',
      date: null,
      startTime: null,
      finishTime: null
    })
  }

  handleVisibleChange = () => {
    this.setState({ visible: !this.state.visible },
      () => {
        setTimeout(() => { this.testInput && this.testInput.focus() }, 1)
      });
  }

  handleClickFilterButton = () => {
    this.props.updateFilters(this.state.id, this.state.title, this.state.host, this.state.date, this.state.startTime, this.state.finishTime);
    console.log(this.state.showDiv);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="filterDropdown">
        <Popover
          placement="bottomRight"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          title={
            <div>
              <Row>
                <Col span={2}>
                </Col>
                <Col style={{ "text-align": "center" }} span={20}>
                  <span></span>
                </Col>
                <Col
                  className="closeIcon" span={2}
                  onClick={this.handleVisibleChange}>
                  <Icon type="close" />
                </Col>
              </Row>
            </div>
          }
          content={
            <div className="filterContainer">
              <Form style={{ width: "100%" }} layout="vertical">
                <Input
                  className="filterInput"
                  size="large"
                  placeholder="Informe o Id"
                  prefix={<Icon type="idcard" />}
                  suffix="" value={this.state.id}
                  onChange={this.handleId}
                  ref={(input) => { this.testInput = input; }}
                />
                <Input
                  className="filterInput"
                  size="large"
                  placeholder="Informe o Título"
                  prefix={<Icon type="info-circle" />}
                  suffix="" value={this.state.title}
                  onChange={this.handleTitle}
                />
                <Input
                  className="filterInput"
                  size="large"
                  placeholder="Informe o Anfitrião"
                  prefix={<Icon type="user" />}
                  suffix="" value={this.state.host}
                  onChange={this.handleHost}
                />
                <Input
                  className="filterInput"
                  size="large"
                  placeholder="Informe os Participantes"
                  prefix={<Icon type="team" />}
                  suffix="" value={this.state.participants}
                  onChange={this.handleParticipants}
                />
                <RangePicker
                  className="filterInput"
                  size="large"
                  placeholder={["Início", "Fim"]}
                  onChange={this.handleDate}
                  value={this.state.date}
                  dateRender={current => {
                    const style = {};
                    if (current.date() === 1) {
                      style.border = '1px solid #1890ff';
                      style.borderRadius = '50%';
                    }
                    return (
                      <div className="ant-calendar-date" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                />
                <div className="buttons">
                  <Button className="clear" size="defaut" onClick={this.handleClear} type="primary">Redefinir</Button>
                  <Button className="search" size="defaut" onClick={this.handleClickFilterButton} htmlType="submit" type="primary">Pesquisar</Button>
                </div>
              </Form>
            </div>
          }
          trigger="click">
          <button className="filterButton">
            <Icon type="filter" theme="filled" />
            <span>Filtros</span>
          </button>
        </Popover>

        {
          false ?
            <div className="filterContainer">
              <Form layout="vertical">
                <div className="filterId2">
                  <Icon style={{ margin: '9px' }} type="info-circle" />
                  <Input placeholder="Adicione o Id" value={this.state.id} autoFocus onChange={this.handleId} />
                </div>
                <div className="filterId2">
                  <Icon style={{ margin: '9px' }} type="info-circle" />
                  <Input placeholder="Adicione o título" value={this.state.title} onChange={this.handleTitle} />
                </div>
                <div className="filterId2">
                  <Icon style={{ margin: '9px' }} type="info-circle" />
                  <Input placeholder="Adicione o anfitrião" value={this.state.host} onChange={this.handleHost} />
                  {/*<Select
                  notFoundContent={<Empty description={"Sem Dados"} />}
                  mode="multiple"
                  placeholder="Adicione o anfitrião"
                  value={this.state.participants}
                  onChange={this.handleHost}
                >
                  {<Option key={"teste"}>teste</Option>}
                </Select>*/}
                </div>
                <div className="filterId2">
                  <Icon style={{ margin: '9px' }} type="info-circle" />
                  <Input placeholder="Adicione os participantes" value={this.state.participants} onChange={this.handleParticipants} />
                  {/*<Select
                  notFoundContent={<Empty description={"Sem Dados"} />}
                  mode="multiple"
                  placeholder="Adicione os participantes"
                  value={this.state.participants}
                  onChange={this.handleParticipants}
                >
                  {<Option key={"teste"}>teste</Option>}
                </Select>*/}
                </div>
                <div className="filterData2">
                  <Icon style={{ margin: '9px' }} type="calendar" />
                  <RangePicker size={"default"}
                    placeholder={["Início", "Fim"]}
                    onChange={this.handleDate}
                    value={this.state.date}
                    dateRender={current => {
                      const style = {};
                      if (current.date() === 1) {
                        style.border = '1px solid #1890ff';
                        style.borderRadius = '50%';
                      }
                      return (
                        <div className="ant-calendar-date" style={style}>
                          {current.date()}
                        </div>
                      );
                    }}
                  />
                </div>
                <div className="filterId2">
                  <Icon style={{ margin: '9px' }} type="clock-circle" />
                  <TimePicker format={"HH:mm"} style={{ right: '63px' }} placeholder="Início" value={this.state.startTime} onChange={this.handleStartTime} />
                  <TimePicker format={"HH:mm"} style={{ right: '90px' }} placeholder="Fim" value={this.state.finishTime} onChange={this.handleFinishTime} />
                </div>
                <div>
                  <div>
                    <Button onClick={this.handleClear} style={{ left: '250px' }} type="primary">Limpar</Button>
                  </div>
                  <div>
                    <Button onClick={this.handleClickFilterButton} style={{ top: '-32px', left: '350px' }} type="primary">Filtrar</Button>
                  </div>
                </div>
              </Form>
            </div> : null
        }

      </div >
    );
  }
}