import api from "./api";
import "dotenv/config";

class SessionAPI {
  donwloadSession = async (storageToken, token) => {
    try {
      const { data } = await api.get(`/videosession/getUrl/${storageToken}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      window.location = data.url;

      return true;
    } catch (error) {
      console.log("error ao ler sessão: ", error.response.data);
    }
  };

  getRecordSession = async (uuidSession, token) => {
    try {
      const response = await api.get(
        `/videosession/conferences/${uuidSession}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      return response.data;
    } catch (err) {
      console.log("falhou!", err);
    }
  };
}

export default new SessionAPI();
