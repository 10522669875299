import api from './api';
import { message } from 'antd';

class BrokerPreservationAPI {

    /**
     * @param {String} sessionId
     * @param {String} storageToken
     * @param {String} owner
     * 
     * @return {Object}
     */
    preserve = async (sessionId, storageToken, owner, callBack) => {
        console.log("<<<<<<<<<<<<<<<<<<<< preserve - preservation >>>>>>>>>>>>");
        console.log(sessionId);
        console.log(storageToken);
        console.log(owner);

        try {
            let response = await api.post("/videosession/preservation",
                {
                    sessionId,
                    storageToken,
                    owner
                },
                { 
                    headers: {'Content-Type': 'application/json'}
                }
            );
            console.log("resposta do servidor: preserve - ", response.data);
            this.consult(response.data.preservationId, callBack);
            message.success("Sessão preservada com sucesso!!");
        }catch(error) {
            console.log("error preserve: ", error.response.data);
        }
    }

    /**
     * 
     */
    consult = async (preservationId, callBack) => {
        console.log("<<<<<<<<<<<<<<<<<<<< consult - preservation >>>>>>>>>>>>");
        try {
            let response = await api.get(`/videosession/consultPreservation/${preservationId}`,
                { 
                    headers: {'Content-Type': 'application/json'}
                }
            );
            console.log("resposta do servidor: consult - ", response.data);
            callBack(response.data[0]);
        }catch(error) {
            console.log("error consult: ", error.response.data);
        }
    }

}

export default new BrokerPreservationAPI();