import React from "react";
import "./MyAccount.css";
import { MyAccountConfig } from "../../compoments/accountConfig";
import { Icon } from "antd";
import { ButtonBack } from "../../compoments/common/buttonBack";

export function MyAccount({ history }) {
  return (
    <div id="myAccount">
      <ButtonBack history={history} go="/ScheduleAppointment" />

      <div className="myAccountContainer">
        <MyAccountConfig history={history} />
      </div>
    </div>
  );
}
