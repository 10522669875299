import React from "react";
import { Icon, message } from "antd";
import Inputmask from "inputmask";
import "./RegisterCompany.css";
import { ProgressBar } from "../../compoments/common/ProgressBar";
import { RegExpAllowedKeys } from "../../modules/keyboard/map";
import $ from "jquery";
import api from "../../services/api";

//TODO Organizar essa classe e fazer as validações
export class RegisterCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconColor: "#999",
      iconSize: "16px",
      error: "",
      company: "",
      cnpj: "",
      address: "",
      email: "",
      pass: "",
      confirmPass: "",
    };
  }

  componentDidMount() {
    var im = new Inputmask("99.999.999/9999-99", { jitMasking: true });
    im.mask(document.getElementById("form-input-cnpj"));
  }

  handleClick = (event) => {
    event.preventDefault();
    this.props.modoCadastro();
  };

  validateConfirmPassword = () => {
    if (this.state.pass.length >= 6) {
      if (this.state.pass === this.state.confirmPass) {
        if (this.state.pass.length >= 6) {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  setError = (element) => {
    $("." + element + "Container").addClass("registerUserInputError");
    $("." + element + "Icon").addClass("registerUserIconError");
    $("#" + element + "Input").focus();
    this.setState({ error: element });
  };

  clearError = (element) => {
    $("." + element + "Container").removeClass("registerUserInputError");
    $("." + element + "Icon").removeClass("registerUserIconError");
  };

  handlerPassword = (e) => {
    if (RegExpAllowedKeys.test(e.target.value) || e.target.value === "") {
      this.setState({ pass: e.target.value });
    }
  };

  handlerConfirmPassword = (e) => {
    if (RegExpAllowedKeys.test(e.target.value) || e.target.value === "") {
      this.setState({ confirmPass: e.target.value });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.clearError();

    if (!this.validateConfirmPassword()) {
      this.setError("confirmPass");
      return;
    }
    console.log("enviando requisição");
    try {
      const response = await api.post(
        "/companies",
        {
          company: this.state.company,
          cnpj: this.state.cnpj,
          address: this.state.address,
          email: this.state.email,
          password: this.state.pass,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.registered) {
        document.getElementById("formRegisterCompany").reset();
        this.setState({
          error: "",
          company: "",
          cnpj: "",
          address: "",
          email: "",
          pass: "",
          confirmPass: "",
        });
        message.success("Sua organização foi registrada com sucesso!");
      }
    } catch {
      this.setError("email");
      message.error("Falha ao cadastrar organização");
    }
  };

  render() {
    return (
      <form
        id="formRegisterCompany"
        className="formRegisterCompany"
        onSubmit={this.handleSubmit}
      >
        <div className="container">
          <Icon
            type="user"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            placeholder="Nome da Companhia"
            type="text"
            name="company"
            onChange={(e) => {
              this.setState({ company: e.target.value });
            }}
            autoFocus
            required
          />
        </div>
        <div className="container cnpjContainer">
          <Icon
            className="cnpjIcon"
            type="idcard"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="form-input-cnpj"
            placeholder="CNPJ"
            name="cnpj"
            pattern="[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}"
            title="99.999.999/9999-99"
            onChange={(e) => {
              this.setState({ cnpj: e.target.value });
            }}
            required
          />
        </div>
        <div className="container">
          <Icon
            type="environment"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            placeholder="Endereço"
            name="address"
            type="text"
            onChange={(e) => {
              this.setState({ address: e.target.value });
            }}
            required
          />
        </div>
        <div className="container emailContainer">
          <Icon
            className="emailIcon"
            type="mail"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="emailInput"
            placeholder="Email"
            type="email"
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
            required
          />
        </div>
        <div className="container">
          <Icon
            type="lock"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="passInput"
            placeholder="Senha"
            type="password"
            value={this.state.pass}
            onChange={this.handlerPassword}
            required
          />
        </div>
        <label style={{ color: "#999" }}>
          Sua senha deve ter no mínimo 6 caracteres.
        </label>
        <div className="containerProgressBar">
          <ProgressBar pass={this.state.pass} />
        </div>
        <div className="container confirmPassContainer">
          <Icon
            className="confirmPassIcon"
            type="lock"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="confirmPassInput"
            placeholder="Confirme sua Senha"
            type="password"
            value={this.state.confirmPass}
            onChange={this.handlerConfirmPassword}
            required
          />
        </div>

        <div className="registerButton">
          <button type="submit">Cadastre-se</button>
        </div>

        <div className="registerFormBar"></div>

        <div className="registerFormFooter">
          <p>
            Sua organização já está cadastrada em nosso sistema?{" "}
            <span
              className="registerFormFooterLink"
              onClick={this.handleClick.bind(this)}
            >
              {" "}
              Crie sua conta.
            </span>
          </p>
        </div>
      </form>
    );
  }
}
