import api from './api';
import { message } from 'antd';

class AuthAPI {

    /**
     * Get conferences scheduled with or by the user.
     *
     * @param {string} token
     * 
     * @returns {Object}
     */
    tokenValidation = async (token) => {
        
        try {
            let response = await api.get('/validToken', { headers: { 'Authorization': 'Bearer ' + token } });
            console.log(response.data);
            return response.data.isValid;
        }catch(error) {
            console.log('-- deu ruim --');
            return false;
        }

    }

}

export default new AuthAPI;