import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Empty,
  Table,
  Layout,
  Button,
  Icon,
  Radio,
  Calendar,
  Badge,
  Select,
  Modal,
} from "antd";
import { MenuV4h, MenuV4hSider } from "../../compoments/menu";
import { ScheduleListButton } from "../../compoments/schedule";
import { AddButton, DetailsSession } from "../../compoments/schedule";
import { AppBar } from "../../compoments/common/topBar";
import "./ScheduleAppointment.css";
import { ConferenceAPI } from "../../services";
import moment from "moment";

import { List, Spin } from "antd";
import reqwest from "reqwest";

import InfiniteScroll from "react-infinite-scroller";
const fakeDataUrl =
  "https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo";

moment.defineLocale("pt-br", {
  weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split(
    "_"
  ),
  monthsShort: "Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez".split("_"),
  weekdays: [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ],
});

let comp;

const months_ = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const columns = [
  {
    title: "Hora",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "Título",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Participantes",
    dataIndex: "users",
    key: "users",
  },
  {
    title: "Sala",
    dataIndex: "Sala",
    key: "Sala",
  },
];

const AddB = Form.create()(AddButton);
const Details = Form.create()(DetailsSession);

export class ScheduleAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      date: moment(),
      eventsForDay: [],
      menu_theme: this.props.menu_theme,
      selectedDate: moment(),
      loading: false,
      hasMore: true,
      data: [],
      visibleModal: false,
      sessionSelected: {},
    };

    console.log(this.state.date);
  }

  async componentDidMount() {
    const response = await ConferenceAPI.getScheduledConferences(
      localStorage.getItem("v4h_token")
    );

    await this.setState({ events: response });
    this.buildMap();
  }

  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setState({
      loading: true,
    });
    if (data.length >= 0) {
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
  };

  onPanelChange = (value, mode) => {
    comp = moment();
    console.log("onPanelChange: ", comp);
    this.setState({
      date: comp,
      selectedDate: value,
    });
  };

  filterDate = (event) => {
    const date = event.schedule_date;

    if (
      date.date() === this.state.selectedDate.date() &&
      date.month() === this.state.selectedDate.month() &&
      date.year() === this.state.selectedDate.year()
    )
      return true;

    return false;
  };

  checkMenu = () => {
    if (localStorage.getItem("v4h_menu") == "MenuV4h") {
      return (
        <MenuV4h
          history={this.props.history}
          menu_theme={localStorage.getItem("v4h_settings_menu_theme")}
        />
      );
    } else {
      return (
        <MenuV4hSider
          history={this.props.history}
          menu_theme={localStorage.getItem("v4h_settings_menu_theme")}
        />
      );
    }
  };

  buildMap = () => {
    console.log("entrou buildMap()");
    let eventsForDay = [];

    for (let i = 0; i < 12; i++) {
      eventsForDay.push(new Map());
    }
    //console.log(this.eventsForDay);
    //console.log(this.state.events)

    for (let i in this.state.events) {
      let date = this.state.events[i].schedule_date;

      if (eventsForDay[date.month()].has(parseInt(date.date()))) {
        let sessions = eventsForDay[date.month()].get(parseInt(date.date()));
        sessions.push({
          idx: i,
          title: this.state.events[i].title,
          schedule_date: this.state.events[i].schedule_date,
        });
        eventsForDay[date.month()].set(parseInt(date.date()), sessions);
      } else {
        let sessions = [];
        sessions.push({
          idx: i,
          title: this.state.events[i].title,
          schedule_date: this.state.events[i].schedule_date,
        });
        eventsForDay[date.month()].set(parseInt(date.date()), sessions);
      }
    }
    console.log(eventsForDay);
    console.log("passou aqui");
    this.setState({ eventsForDay: eventsForDay });
  };

  getListData = (value) => {
    let listData = [];
    try {
      if (this.state.eventsForDay[value.month()].has(value.date())) {
        let sessions = this.state.eventsForDay[value.month()].get(value.date());

        for (let i in sessions) {
          if (
            sessions[i].schedule_date.year() !== this.state.selectedDate.year()
          )
            return [];

          listData.push(
            <div className="content">
              <div className="line" />
              <span>{sessions[i].title}</span>
            </div>
          );
        }
      }
    } catch {
      return [];
    }

    return listData;
  };

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <ul className="eventsCalendar">
        {listData.map((item) => (
          <li className="listItemCalendar">{item}</li>
        ))}
      </ul>
    );
  };

  render() {
    return (
      <Layout className="ScheduleAppointment-container">
        {this.checkMenu()}

        <div className="ScheduleAppointment-body">
          <Row className="appbar">
            <Col span={24}>
              <AppBar title="Agenda" />
            </Col>
          </Row>
          <Row className="headercalendar">
            <Col className="leftbutton" span={12}>
              <button
                className="todayButton"
                onClick={() => {
                  this.setState({
                    selectedDate: moment(),
                  });
                }}
              >
                <span className="todaySpan">Hoje</span>
              </button>
              <button
                className="prevButton"
                onClick={(previousMonth) => {
                  const newValue = this.state.selectedDate.clone();
                  const month = newValue.month() - 1;
                  newValue.month(month);
                  this.setState({
                    selectedDate: newValue,
                  });
                }}
              >
                <Icon className="iconButton" type="caret-left" theme="filled" />
              </button>
              <button
                className="nextButton"
                onClick={(nextMonth) => {
                  const newValue = this.state.selectedDate.clone();
                  const month = newValue.month() + 1;
                  newValue.month(month);
                  this.setState({
                    selectedDate: newValue,
                  });
                }}
              >
                <Icon
                  className="iconButton"
                  type="caret-right"
                  theme="filled"
                />
              </button>
              <span>
                {`${
                  this.state.selectedDate &&
                  this.state.selectedDate.format("MMMM, YYYY")
                }`}
              </span>
            </Col>

            <Col className="rightbutton" span={12}>
              <AddB />
              <button className="monthButton">
                <span>Mês</span>
              </button>
              <button className="yearButton">
                <span>Ano</span>
              </button>
            </Col>
          </Row>
          <Row className="schedulecontainer">
            <Col span={16} className="schedule">
              <div id="schendule">
                <div style={{ background: "#ffffff", width: "100%" }}>
                  <Calendar
                    dateCellRender={this.dateCellRender}
                    fullscreen={true}
                    onSelect={this.onPanelChange}
                    value={this.state.selectedDate}
                    headerRender={() => {}}
                  />
                </div>
              </div>
            </Col>
            <Col span={8} className="activitiesDay">
              <div className="dayInfo">
                {this.state.selectedDate.format("dddd, D MMMM")}
              </div>
              <div className="demo-infinite-container">
                <InfiniteScroll
                  initialLoad={true}
                  pageStart={0}
                  loadMore={this.handleInfiniteOnLoad}
                  hasMore={!this.state.loading && this.state.hasMore}
                  useWindow={false}
                >
                  <List
                    dataSource={this.state.events.filter(this.filterDate)}
                    renderItem={(item) => (
                      <List.Item key={item.id}>
                        <div
                          className="listItem"
                          onClick={() =>
                            this.setState({
                              sessionSelected: item,
                              visibleModal: true,
                            })
                          }
                        >
                          <div className="lineContent">
                            <div className="line" />
                          </div>
                          <span className="time">
                            {item.start_time.format("HH:mm")}
                          </span>
                          <div className="title-host">
                            <h4 className="title">{item.title}</h4>
                            <span className="host">{item.host_id.name}</span>
                          </div>
                          <ScheduleListButton
                            uuidSession={item.uuid}
                            history={this.props.history}
                            confName={item.title}
                            typeConference={item.type}
                          />
                        </div>
                      </List.Item>
                    )}
                  >
                    {this.state.loading && this.state.hasMore && (
                      <div className="demo-loading-container">
                        <Spin />
                      </div>
                    )}
                  </List>
                </InfiniteScroll>
              </div>
              {/* <Details
                session={this.state.sessionSelected}
                visible={this.state.visibleModal}
                close={() => this.setState({ visibleModal: false })}
              /> */}
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }
}
