import React from 'react';
import { Menu, Icon, Layout } from 'antd';
import MenuItens from './MenuItens'

const { Header, Content, Footer, Sider } = Layout;

export class MenuV4hSider extends React.Component {
    state = {
        current: this.props.history.location.pathname,
        collapsed: true,
        theme: this.props.menu_theme,
    };

    componentDidMount() {
        console.log( 'teste-menuV4hSider', localStorage.getItem('v4h_settings_menu_theme') );
    }

    handleClick = e => {
        if(e.key == 'setting') {
            return null
        }
        console.log(e.key);
        this.setState({
        current: e.key,
        });
        this.props.history.push(`${e.key}`);
    };

    toggleCollapsed = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
    };
    
    render() {
        return (
            <div id="menuSider">
                <Sider trigger={null} collapsed={this.state.collapsed} style= {{minHeight: '100vh'}} theme={this.state.theme}>
                    <MenuItens history={this.props.history} mode='inline' menu_theme={this.state.theme} collapsedMenu={this.toggleCollapsed} />   
                </Sider>
            </div>
        );
    }
}