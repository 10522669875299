/* global config */

import React from "react";
import api from "../services/api";
import { ResultError } from "../compoments/resultMsg";
import { Spin } from "antd";
import "../pages/scheduleAppointment/ScheduledSessionConference.css";
require("dotenv/config");

export default class ProxyV4h extends React.Component {
  constructor(props, history) {
    super(props);

    this.v4h_ss_domain = `${config.connection.conf_url}/session-service`;

    this.client = new window.coreapi.Client(this.v4h_ss_domain);
    this.coreapi = window.coreapi;
    this.schema = window.schema;
    this.loggedIn = false;
    this.node = {};
    this.jApi = {};
    this.sessionId = "";
    this.token = { access: "", refresh: "" };
    this.EndedListener = "";
    this.uuidSession = "";
    this.token_jwt = "";
    this.locate = "pt-BR";

    /** Session Settings **/
    this.history = history;
    this.uuidSession = history.location.state.uuidSession;
    this.settingsCam = history.location.state.camStatus;
    this.settingsMic = history.location.state.microphoneStatus;
    this.typeConference = history.location.state.typeConference;
    this.userName =
      localStorage.getItem("v4h_user_firstName") +
      " " +
      localStorage.getItem("v4h_user_lastName");

    this.error = false;
  }

  HandlerstartVideoConf = async () => {
    console.log(" ##### DADOS #####");
    console.log(this.history);
    console.log(this.settingsCam);
    console.log(this.settingsMic);
    console.log(this.typeConference);
    console.log(this.v4h_ss_domain);
    console.log(this.uuidSession);
    console.log(this.client);
    console.log(this.schema);
    console.log(this.coreapi);
    console.log(" ##### DADOS #####");

    try {
      const response = await api.post("/videosession/login", null, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("v4h_token"),
        },
      });
      const tokenJwt = await api.get(
        `/videosession/tokenJwt/${this.uuidSession.split("-").join("")}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("v4h_token"),
          },
        }
      );
      this.token_jwt = tokenJwt.data;
      console.log("Token JWT:" + this.token_jwt);
      this.token.access = response.data.access;
      this.token.refresh = response.data.refresh;

      console.log(this.token.access);
      console.log(this.token.refresh);
      const login = await this.loginWithToken(response.data.access);
      console.log("## login ##", login);

      await this.startConference(
        this.uuidSession.split("-").join(""),
        document.querySelector("#meet"),
        "100%",
        "100%"
      );
      document.getElementById("spin").remove();
    } catch (err) {
      console.log("Não conseguiu obter o token {token error!}");
      console.error(err.data);
      this.error = <ResultError history={this.history} />;
    }
  };

  conferenceEnded = () => {
    document.getElementById("meet").innerHTML = "";
    this.history.push("/ScheduleAppointment");
  };

  loginWithToken = (access_token) => {
    return new Promise(function (resolve, reject) {
      let action = ["api", "token", "verify", "create"];
      let params = { token: access_token };
      this.client
        .action(this.schema, action, params)
        .then(function (result) {
          let auth = new this.coreapi.auth.TokenAuthentication({
            token: access_token,
          });
          this.client = new this.coreapi.Client({ auth: auth });
          this.loggedIn = true;
          this.token["access"] = access_token;
          resolve(this.token);
        })
        .catch(function (error) {
          console.error(error);
          this.loggedIn = false;
          reject(error);
        });
    });
  };

  startConference = (sessionId, parentNode, width, height) => {
    return new Promise(function (resolve, reject) {
      let action = ["conferences", "update"];
      let params = { sessionId: sessionId, conf_state: 1 };
      this.client
        .action(this.schema, action, params)
        .then(function (result) {
          sessionId = result["sessionId"];
          console.log("testteeee ##", result);
          console.log(
            "V4H-rest-cli-api 1:: starting conf on room " + sessionId
          );
          this._openJitsi(
            sessionId,
            result["conf_auth"],
            parentNode,
            width,
            height
          );
          resolve(sessionId);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    });
  };

  _endedConference = (sessionId) => {
    let action = ["conferences", "update"];
    let params = { sessionId: sessionId, conf_state: 3 };
    this.client.action(this.schema, action, params).then(function (result) {
      console.log(
        "V4H-rest-cli-api :: updated conf " +
          result["sessionId"] +
          " conf_state to " +
          result["conf_state"]
      );
    });
    this.conferenceEnded();
  };

  _openJitsi = async (sessionId, jwt, parentNode, width, height) => {
    width = width || 640;
    height = height || 480;
    const domain = "conf.v4h.cloud";
    const options = {
      roomName: sessionId,
      width: width,
      height: height,
      noSsl: false,
      parentNode: parentNode,
      configOverwrite: { autoRecord: true },
      jwt: jwt,
    };

    this.jApi = new window.JitsiMeetExternalAPI(domain, options);

    /* audio and camera settings of conference */
    this.jApi.executeCommand("displayName", `${this.userName}`);
    if (this.settingsCam == "false") {
      this.jApi.executeCommand("toggleVideo");
    }
    if (this.settingsMic == "false") {
      this.jApi.executeCommand("toggleAudio");
    }

    this.jApi.addEventListener("readyToClose", () =>
      this._endedConference(sessionId)
    );

    const { data } = await api.get(
      `/videosession/getToken/${this.uuidSession}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("v4h_token"),
        },
      }
    );
    /** Agenda **/

    this.jApi.executeCommand(
      "link",
      `https://agenda.v4h.cloud/visitantes/${
        this.typeConference
      }/${this.uuidSession.split("-").join("")}/${this.data.token}`
    );

    /** Conf **/
    this.jApi.executeCommand(
      "visitorLink",
      `https://conf.v4h.cloud/${this.uuidSession.split("-").join("")}?jwt=${
        this.token_jwt
      }`
    );
  };

  render() {
    return (
      <div className="conference-container">
        <div id="meet">
          <div id="spin">{this.error ? this.error : <Spin size="large" />}</div>
        </div>
      </div>
    );
  }
}
