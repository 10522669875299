import React from 'react';
import { Modal, Icon } from 'antd';
import { ConferenceAPI, BrokerPreservationAPI } from '../../../services';

import "../css/BtnBrokerPresevation.css"

export class BtnBrokerPresevation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uuidSession: props.uuidSession,
            modalVisible: false,
            session: '',
            preservedSession: '',
            status: 0
        }
    }

    componentDidMount() {
        const { uuidSession } = this.props
        const getUrl = async () => {
            try{
                const response = await ConferenceAPI.readSession(uuidSession, localStorage.getItem('v4h_token'));
                this.setState({session: response});

                if(response.storageToken !== "[]")
                    this.setState({status: 1});
                if(response.DLTId !== '[]')
                    await BrokerPreservationAPI.consult(JSON.parse(response.DLTId)[0], (data) => this.setState({preservedSession: data}) );

            }catch(err) {
                console.log("falhou!", err);
            }
        }
        getUrl();
    }

    preserve = () => {
        const { sessionId, storageToken, owner } = this.state.session;
        return(
            <div>
                <div>
                    Esta sessão ainda não foi preservada, para preservá-la clique no botão abaixo
                </div>
                <div>
                    <button
                        onClick={()=>BrokerPreservationAPI.preserve(sessionId, JSON.parse(storageToken)[0], owner, (data) => this.setState({preservedSession: data}))}>
                        Preservar Sessão
                    </button>
                </div>
            </div>
        );
    }

    detailsPreservation = () => {
        const { preservedSession } = this.state;
        let dados = [];
        for(var x in preservedSession) {
            dados.push(<div key={x}>
                <span>{`${x}: `}</span>
                <span>{JSON.stringify(this.consultType(preservedSession[x]))}</span>
            </div>);
        }
        return dados;
    }

    consultType = (data) => {
        if(typeof data == "string")
            return data;

        if(typeof data == "number")
            return data;
        
        if(data instanceof Array)
            return data.join(', ');

        return data;
    }

    closeModal = () => {
        this.setState({modalVisible: false});
    };    

    HandlerClick = () => {
        this.setState({modalVisible: true});
    }

    render(){
        const { uuidSession, status, modalVisible, session, preservedSession } = this.state;

        return(
            <div className="perservationItemContainer">
                <div className={"perservationButton " + (status? "" : "disabled")}>
                    <button onClick={this.HandlerClick.bind(this)}>
                        <Icon type="play-square" />
                    </button>
                </div>
                <Modal
                    title={"Vídeo gravado da sessão: " + uuidSession}
                    centered
                    visible={modalVisible}
                    onOk={this.closeModal}
                    onCancel={this.closeModal}
                    closable={true}
                    keyboard={false}
                    maskClosable={false}
                    footer={null}
                    height="60%"
                    width="60%">
                    <div>
                        <div>
                            {(session.DLTId === '[]' && !preservedSession)? this.preserve() : this.detailsPreservation() }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}