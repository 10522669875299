import React from "react";
import { ConferenceSettingsBody } from "../../compoments/conferenceSettings";
import "./ConferenceSettings.css";
import { ButtonBack } from "../../compoments/common/buttonBack";

export function ConferenceSettings({ history, match }) {
  return (
    <div className="conferenceSettings-container">
      <ButtonBack history={history} go="/ScheduleAppointment" />

      <div className="conferenceSettings-box">
        <ConferenceSettingsBody history={history} match={match} />
      </div>
    </div>
  );
}
