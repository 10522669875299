import React from "react";
import Routes from "./routes";
import "./App.css";
require("dotenv/config");

function App() {
  return <Routes />;
}

export default App;
