import React from "react";
import "./ChangePassword.css";
import $ from "jquery";
import { ProgressBar } from "../../compoments/common/ProgressBar";
import api from "../../services/api";
import { Icon, message } from "antd";
import { RegExpAllowedKeys } from "../../modules/keyboard/map";

export class ChangePassword extends React.Component {
  constructor(props, history) {
    super(props, history);

    let params = new URLSearchParams(this.props.location.search);

    this.state = {
      pass: "",
      confirmPass: "",
      passLenght: "",
      error: "error",
      token: this.props.match.params.token,
      userId: this.props.match.params.account,
      goTo: params.get("goTo"),
    };
  }

  async componentDidMount() {
    //Check that the token is valid.
    try {
      const response = await api.get("/validToken", {
        headers: { Authorization: "Bearer " + this.state.token },
      });
    } catch {
      this.props.history.push(this.state.goTo);
    }

    //focus on password input.
    $("#input_password").focus();
  }

  goBack = () => {
    const { goTo } = this.state;

    this.props.history.push(goTo);
  };

  validatePassword = () => {
    if (this.state.pass != this.state.confirmPass) {
      this.setState({ error: "As senhas não são idênticas." });
      $("#input_confirmPassword").focus();
      return false;
    }

    if (this.state.pass.length < 6) {
      this.setState({ error: "Senha muito curta." });
      $("#input_password").focus();
      return false;
    }

    return true;
  };

  handlerPassword = (e) => {
    if (RegExpAllowedKeys.test(e.target.value) || e.target.value === "") {
      this.setState({ pass: e.target.value });
    }
  };

  handlerConfirmPassword = (e) => {
    if (RegExpAllowedKeys.test(e.target.value) || e.target.value === "") {
      this.setState({ confirmPass: e.target.value });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    //valid password
    if (!this.validatePassword()) {
      $(".error-msg").addClass("error-msg-visible");
      return;
    }

    try {
      const response = await api.put(
        `/users/update/${this.state.userId}`,
        {
          password: this.state.pass,
        },
        {
          headers: { Authorization: "Bearer " + this.state.token },
        }
      );

      for (var i = 0; i < this.state.pass.length; i++) {
        this.state.passLenght += "*";
      }

      localStorage.setItem("v4h_user_passwordLength", this.state.passLenght);

      console.log("response", response);

      const title = "A sua senha foi alterada com sucesso!";
      const subTitle = "Agora você pode efetuar o login com sua nova senha.";

      return this.props.history.push("/resultSucess", {
        title,
        subTitle,
        goTo: `${this.state.goTo}`,
      });
    } catch {
      message.error("Senha Inválida");
    }
  };

  render() {
    return (
      <div className="changePassword-container">
        <div className="menu">
          <div className="backButton">
            <button onClick={this.goBack}>
              <Icon type="left" />{" "}
              {this.state.goTo !== "/" ? "voltar" : "Ir para página principal"}
            </button>
          </div>
        </div>

        <div className="changePassword-form">
          <form onSubmit={this.handleSubmit}>
            <label className="form-label">Nova senha</label>
            <input
              id="input_password"
              type="password"
              value={this.state.pass}
              onChange={this.handlerPassword}
            />

            <label style={{ color: "#999" }}>
              Sua senha deve ter no mínimo 6 caracteres.
            </label>
            <div className="progressBar">
              <ProgressBar pass={this.state.pass} />
            </div>

            <label className="form-label">Confirmar nova senha</label>
            <input
              id="input_confirmPassword"
              type="password"
              value={this.state.confirmPass}
              onChange={this.handlerConfirmPassword}
            />
            <div className="error-msg">{this.state.error}</div>

            <button type="submit" className="submit">
              Confirmar
            </button>
          </form>
        </div>
      </div>
    );
  }
}
