import React, { useState } from "react";
import "./Signin.css";
import $ from "jquery";
import api from "../../services/api";
import { ButtonBack } from "../../compoments/common/buttonBack";
import logo from "../../assets/v4h/login-logo.png";

require("dotenv/config");

export function Signin({ history }) {
  const [pass, setPass] = useState([]);

  async function handleSubmit(e) {
    e.preventDefault();

    const response = await api.post(
      "/users/checkpassword",
      {
        uuid: localStorage.getItem("v4h_uuid"),
        password: pass,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("v4h_token"),
        },
      }
    );

    if (!response.data.valid) {
      $("#password-form").addClass("error-form");
      $(".error-msg").addClass("error-msg-visible");
      return;
    }

    history.push(
      `/changepassword/${localStorage.getItem("v4h_uuid")}/${
        response.data.token
      }?goTo=/MyAccount`
    );
  }

  return (
    <div className="singin-container">
      <ButtonBack history={history} go="/MyAccount" />
      <div className="singin-form">
        <form onSubmit={handleSubmit}>
          <div className="container-img">
            <img src={logo} alt="V4H" />
          </div>
          <h2>
            {localStorage.getItem("v4h_user_firstName") +
              " " +
              localStorage.getItem("v4h_user_lastName")}
          </h2>
          <div className="user_email">
            {localStorage.getItem("v4h_user_email")}
          </div>
          <div className="msg_form">
            <p>Para continuar, primeiro confirme que é realmente você</p>
          </div>
          <input
            id="password-form"
            placeholder="Digite sua senha"
            type="password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            autoFocus
          />
          <div className="error-msg">Senha inválida.</div>

          <button type="submit" className="submit">
            Confirmar
          </button>
        </form>
      </div>
    </div>
  );
}
