import React from "react";
import { Modal } from "antd";
import $ from "jquery";
import "./ConfigModal.css";
import { UserAPI } from "../../services";

export class ConfigModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: localStorage.getItem("v4h_user_firstName"),
      lastName: localStorage.getItem("v4h_user_lastName"),
      email: localStorage.getItem("v4h_user_email"),
      password: "",
      confirmPassword: "",
    };
  }

  initForm = (onCancel) => {
    switch (this.props.title) {
      case "Nome":
        return (
          <div id="inputConfigModal">
            <form onSubmit={this.handlerOk}>
              <label className="form-label">Primeiro nome</label>
              <input
                id="inputConfigModal_firstName"
                type="text"
                value={this.state.firstName}
                onChange={(event) => {
                  this.setState({ firstName: event.target.value });
                }}
              />
              <label className="form-label">Segundo nome</label>
              <input
                id="inputConfigModal_lastName"
                type={"text"}
                value={this.state.lastName}
                onChange={(event) => {
                  this.setState({ lastName: event.target.value });
                }}
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        );
      case "Email":
        return (
          <div id="inputConfigModal">
            <form onSubmit={this.handlerOk}>
              <label className="form-label">Email</label>
              <input
                id="inputConfigModal_email"
                type="text"
                value={this.state.email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
                autoFocus
              />
              <label className="error-msg">
                O endereço de email já está em uso.
              </label>
              <button type="submit">Submit</button>
            </form>
          </div>
        );

      case "Senha":
        return (
          <div id="inputConfigModal">
            <form onSubmit={this.handlerOk}>
              <label className="form-label">Nova senha</label>
              <input
                id="inputConfigModal_password"
                type="password"
                value={this.state.password}
                onChange={(event) => {
                  this.setState({ password: event.target.value });
                }}
                autoFocus
              />
              <label className="form-label">Confirmar nova senha</label>
              <input
                id="inputConfigModal_confirmPassword"
                type="password"
                value={this.state.confirmPassword}
                onChange={(event) => {
                  this.setState({ confirmPassword: event.target.value });
                }}
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        );
      default:
        return (
          <div id="inputConfigModal">
            <button
              className="btnDeleteAccount"
              onClick={this.handlerDeleteAccount}
            >
              Apagar Conta
            </button>
            <button className="btnCancel" onClick={this.handlerCancel}>
              Cancelar
            </button>
          </div>
        );
        break;
    }
  };

  handlerDeleteAccount = async () => {
    const { error } = await UserAPI.delete(
      localStorage.getItem("v4h_uuid"),
      localStorage.getItem("v4h_token")
    );

    if (error) return;

    localStorage.clear();

    const title = "A sua conta foi excluída com sucesso!";
    const subTitle = "Esperamos que você volte em breve";

    this.props.history.push("/resultSucess", {
      title,
      subTitle,
    });
  };

  handlerErrors = () => {
    if (!this.state.firstName) {
      $("#inputConfigModal_firstName").addClass("form-error");
      $("#inputConfigModal_firstName").focus();
      $("#inputConfigModal_firstName").keyup(() => {
        $("#inputConfigModal_firstName").removeClass("form-error");
      });
      return true;
    } else if (!this.state.lastName) {
      $("#inputConfigModal_lastName").addClass("form-error");
      $("#inputConfigModal_lastName").focus();
      $("#inputConfigModal_lastName").keyup(() => {
        $("#inputConfigModal_lastName").removeClass("form-error");
      });
      return true;
    }
    return false;
  };

  handlerClear = () => {
    this.setState({
      firstName: localStorage.getItem("v4h_user_firstName"),
      lastName: localStorage.getItem("v4h_user_lastName"),
      email: localStorage.getItem("v4h_user_email"),
      password: "",
      confirmPassword: "",
    });
  };

  handlerCancel = () => {
    if (this.props.title == "Nome") {
      $("#inputConfigModal_firstName").removeClass("form-error");
      $("#inputConfigModal_lastName").removeClass("form-error");
    }

    const { onCancel } = this.props;
    onCancel();
  };

  handlerOk = async (e) => {
    e.preventDefault();

    if (this.handlerErrors()) {
      return;
    }

    const { onOk } = this.props;
    await onOk(this.state);
    this.handlerClear();
  };

  render() {
    const { visible, title, closable, keyboard } = this.props;

    return (
      <Modal
        width="350px"
        visible={visible}
        title={title}
        footer={null}
        onCancel={this.handlerCancel}
        centered
        closable={closable}
        keyboard={keyboard}
        afterClose={this.handlerClear}
        maskClosable={false}
      >
        {this.initForm(this.handlerCancel)}
      </Modal>
    );
  }
}
