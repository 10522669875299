import React from "react";
import moment from "moment";

import { DatePicker, Icon } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./HeaderFilter.css";
import "../css/range.css";
import FilterBtn from "../filter/FilterButton";

const { RangePicker } = DatePicker;

export default class HeaderFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeInterval: this.props.date,
    }
  }

  componentWillReceiveProps = props => {
    this.setState({ timeInterval: props.date });
  }

  handleDate = (e) => {
    this.state.timeInterval[0] = e[0];
    this.state.timeInterval[1] = e[1];
    
    this.props.setfiltereddata(e[0], e[1]);
  }

  handleFilters = (filter) => {
    return;
    let { filters } = this.state;

    for (let i in filter) {
      filters.set(i, filter[i])
    }

    this.setState({
      filters: filters
    })

    console.log(this.state);

    this.updateData();
  }

  updateData = () => {
    const data = this.props.data;
    const time = this.state.timeInterval;

    let filteredData = data.filter((item) => {
      let itemDate = moment(item.startTime);      

      if(itemDate.date() >= time[0].date() && itemDate.month() >= time[0].month())
        if(itemDate.date() <= time[1].date() && itemDate.month() <= time[1].month()) {
          return true;
        }
      
    })

    this.props.setfiltereddata(filteredData, time);
  }

  renderFooter = () => {
    return(
      <button className="btnClearFilter" onClick={() => this.handleDate(this.props.time)}>Limpar</button>
    );
  }

  render() {
    return (
      <div id="headerFilterContainer">
        <div className="leftButtons">
          <div id="rangePagination">
            <RangePicker
              defaultValue={[this.state.timeInterval[0], this.state.timeInterval[1]]}
              value={[this.state.timeInterval[0], this.state.timeInterval[1]]}
              allowClear={false}
              separator={"-"}
              renderExtraFooter={this.renderFooter}
              suffixIcon={[]}
              size={"large"}
              format={"D MMM Y"}
              onChange={this.handleDate}
              className="range"
              dateRender={current => {
                const style = {};
                if (current.date() === 1) {
                  style.border = '1px solid #1890ff';
                  style.borderRadius = '50%';
                }
                return (
                  <div className="ant-calendar-date" style={style}>
                    {current.date()}
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="rightButtons" style={{"display": "none"}}>
          <FilterBtn updateFilters={this.handleFilters} />
          <button className="searchButton">
            <FontAwesomeIcon className="icon" icon={faSearch} />
          </button>
        </div>
      </div>
    );
  }
}