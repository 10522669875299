/* global config */

import React, { useState, useEffect } from "react";
import "./Login.css";
import { UserAPI } from "../../services/index";
import { message, notification } from "antd";

import logo from "../../assets/v4h/login-logo.png";

require("dotenv/config");

export function Login({ history, match }) {
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();

  useEffect(() => {
    if (localStorage.getItem("isAuthenticated") == "false") {
      notification.error({
        message: "Token de acesso expirado.",
        description: "efetue o login para continuar.",
      });
    }
  }, [match.params.register]);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      let response = await UserAPI.login(email, pass);

      let password = "";
      for (var i in pass) {
        password += "*";
      }

      response.password = password;

      localStorage.setItem("v4h_uuid", response.uuid);
      localStorage.setItem("v4h_token", response.token);
      localStorage.setItem("v4h_user_firstName", response.firstName);
      localStorage.setItem("v4h_user_lastName", response.lastName);
      localStorage.setItem("v4h_user_passwordLength", response.password);
      localStorage.setItem("v4h_user_email", response.email);
      localStorage.setItem("v4h_user_companyName", response.companyName);
      localStorage.setItem("v4h_menu", "MenuV4h");
      localStorage.setItem("v4h_settings_menu_theme", "dark");
      localStorage.setItem("isAuthenticated", true);

      if (match.params.page) {
        return history.push(`/${match.params.page}`);
      }

      history.push("/ScheduleAppointment");
    } catch {
      console.log("falha no envio dos dados.");
    }
  }

  function forgetPassword(e) {
    e.preventDefault();
    history.push("/forgetPassword");
  }

  function handleRegister(e) {
    e.preventDefault();
    history.push("/register/true");
  }

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <div className="container-img">
          <img src={logo} alt="V4H" />
        </div>
        <input
          id="input_email"
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
        />
        <input
          id="input_pass"
          placeholder="Senha"
          type="password"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
        />
        <button type="submit" className="submit">
          Entrar
        </button>
        <div className="forgetPassword" onClick={forgetPassword}>
          Esqueci minha senha
        </div>
      </form>

      {match.path === "/cadastro" && (
        <div className="registerButtonContainer" onClick={handleRegister}>
          <svg className="registerButtonSvg">
            <rect className="registerButtonRect" />
          </svg>
          <div className="registerButtonText">Cadastre-se</div>
        </div>
      )}
    </div>
  );
}
