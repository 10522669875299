import React from 'react';
import { Form, Button, Col, Row, Input, Dropdown, Icon, DatePicker, TimePicker, Empty, Select, Popover } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import './FilterButton.css'

class FilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      uuid: '',
      title: '',
      host: '',
      participants: '',
      startTime: null,
      finishTime: null,
    };
  }

  handleId = (e) => {
    console.log(e.target.value);
    this.setState({
      uuid: e.target.value
    });
  }

  handleTitle = (e) => {
    this.setState({
      title: e.target.value
    });
  }

  handleHost = (e) => {
    console.log(e)
    this.setState({
      host: e.target.value
    });
  }

  handleParticipants = (e) => {
    this.setState({
      participants: e.target.value
    });
  }

  handleStartTime = (e) => {
    this.setState({
      startTime: e
    });
  }

  handleFinishTime = (e) => {
    this.setState({
      finishTime: e
    });
  }

  handleClear = () => {
    this.setState({
      uuid: '',
      title: '',
      host: '',
      participants: '',
      date: null,
      startTime: null,
      finishTime: null
    })
  }

  handleVisibleChange = () => {
    this.setState({ visible: !this.state.visible },
      () => {
        setTimeout(() => { this.testInput && this.testInput.focus() }, 1)
      });
  }

  handleClickFilterButton = () => {
    this.props.updateFilters({
      uuid: this.state.uuid,
      title: this.state.title,
      host: this.state.host,
      participants: this.state.host,
      startTime: this.state.startTime,
      finishTime: this.state.finishTime,
    });
  }

  render() {

    return (
      <div className="filterDropdown">
        <Popover
          placement="bottomRight"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          title={
            <div>
              <Row>
                <Col span={2}>
                </Col>
                <Col style={{ "text-align": "center" }} span={20}>
                  <span></span>
                </Col>
                <Col
                  className="closeIcon" span={2}
                  onClick={this.handleVisibleChange}>
                  <Icon type="close" />
                </Col>
              </Row>
            </div>
          }
          content={
            <div className="filterContainer">
              <Form style={{ width: "100%" }} layout="vertical">
                <Input
                  className="filterInput"
                  size="large"
                  placeholder="Informe o uuid"
                  prefix={<Icon type="idcard" />}
                  suffix="" value={this.state.uuid}
                  onChange={this.handleId}
                  ref={(input) => { this.testInput = input; }}
                />
                <Input
                  className="filterInput"
                  size="large"
                  placeholder="Informe o Título"
                  prefix={<Icon type="info-circle" />}
                  suffix="" value={this.state.title}
                  onChange={this.handleTitle}
                />
                <Input
                  className="filterInput"
                  size="large"
                  placeholder="Informe o Anfitrião"
                  prefix={<Icon type="user" />}
                  suffix="" value={this.state.host}
                  onChange={this.handleHost}
                />
                <Input
                  className="filterInput"
                  size="large"
                  placeholder="Informe os Participantes"
                  prefix={<Icon type="team" />}
                  suffix="" value={this.state.participants}
                  onChange={this.handleParticipants}
                />
                <div className="buttons">
                  <Button className="clear" size="default" onClick={this.handleClear} type="primary">Redefinir</Button>
                  <Button className="search" size="default" onClick={this.handleClickFilterButton} htmlType="submit" type="primary">Pesquisar</Button>
                </div>
              </Form>
            </div>
          }
          trigger="click">
          <button className="filterButton">
            <FontAwesomeIcon className="icon" icon={faFilter} />
            <span>Filtros</span>
          </button>
        </Popover>
      </div >
    );
  }
}

const FilterBtn = Form.create({ name: "filterbutton" })(FilterButton);

export default FilterBtn;