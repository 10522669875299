import React from "react";
import "./ButtonBack.css";
import { Icon } from "antd";

export function ButtonBack({ go, history }) {
  function goBack() {
    history.push(go);
  }

  return (
    <div className="menuBackButton">
      <div className="backButton">
        <button onClick={goBack}>
          <Icon type="left" /> voltar
        </button>
      </div>
    </div>
  );
}
