import React from "react";
import { Icon, message, Tooltip } from "antd";
import api from "../../services/api";
import copy from "copy-to-clipboard";
import "./ScheduleListButton.css";
import { ConferenceAPI } from "../../services";
require("dotenv/config");

export function ScheduleListButton(props) {
  const { uuidSession, typeConference } = props;

  const handlerstartVideoConf = () => {
    props.history.push(`/scheduledSession/${uuidSession.split("-").join("")}`, {
      confName: props.confName,
      typeConference: props.typeConference,
    });
  };

  const handlerSharedLink = async () => {
    const { data } = await api.get(
      `/videosession/getLinkAccess/${uuidSession}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("v4h_token"),
        },
      }
    );
    copy(data.shortLink);
    message.success("Link copiado para sua área de transferência!");
  };

  return (
    <div className="ScheduleListButton-container">
      <Tooltip placement="topLeft" title={"Entrar na Sessão"}>
        <button>
          <Icon type="login" onClick={handlerstartVideoConf} />
        </button>
      </Tooltip>
      <Tooltip placement="bottomLeft" title={"Copiar link da sessão"}>
        <button>
          <Icon type="share-alt" onClick={handlerSharedLink} />
        </button>
      </Tooltip>
    </div>
  );
}
