import React from 'react';
import './AppBar.css';

export class AppBar extends React.Component {
    
    render() {
        return(
            <div className="appbarcontainer">
                <h1>{this.props.title}</h1>
            </div>
        )
    }
}