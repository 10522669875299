import React from "react";
import { Icon, InlineIcon } from "@iconify/react";
import exclamationCircle from "@iconify/icons-fa/exclamation-circle";
import {
  notification,
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Empty,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Spin,
  Modal,
} from "antd";
import "./AddButton.css";
import { ConferenceAPI } from "../../services/";
import api from "../../services/api";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

export class DetailsSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
      updateCollaborators: true,
      collaborators: [],
      description: "",
      loading: "",
      typeConference: "conference",
    };

    this.children = [];
    this.collaboratorsUuid = [];
    this.collaboratorsEmail = [];
  }

  async componentDidMount() {
    const response = await api.get("/companies/users", {
      headers: { Authorization: "Bearer " + localStorage.getItem("v4h_token") },
    });

    for (var x in response.data) {
      if (response.data[x].uuid === localStorage.getItem("v4h_uuid")) {
        continue;
      }
      this.children.push(
        <Option key={[response.data[x].uuid, response.data[x].email]}>
          {response.data[x].email}
        </Option>
      );
    }
  }

  componentWillReceiveProps(props) {
    const { session } = this.props;
    let users = [];

    if (session.title && this.state.updateCollaborators) {
      console.log("atualizando os usuários");
      session.users.map((user) => users.push(`${user.uuid},${user.email}`));
      this.setState({ collaborators: users, updateCollaborators: false });
    }

    this.setState({ visible: props.visible });
  }

  showDrawer = () => {
    this.setState(
      {
        visible: true,
      },
      () => {
        setTimeout(() => {
          this.testInput && this.testInput.focus();
        }, 1);
      }
    );
  };

  showDeleteConfirm = (uuid) => {
    confirm({
      title: "Você realmente deseja apagar esse evento?",
      icon: <Icon icon={exclamationCircle} width="30px" height="30px" />,
      width: "500px",
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        return new Promise((resolve, reject) => {
          ConferenceAPI.deleteSchedule(uuid, resolve, reject);
        }).catch(() => console.log("Oops errors!"));
        // ConferenceAPI.deleteSchedule(uuid);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  onClose = () => {
    this.props.close();
    this.props.form.resetFields();
  };

  handleTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  handleTypeConference = (value) => {
    this.setState({
      typeConference: value,
    });
  };

  handleCollaborators = (value) => {
    console.log("valueee: ", value);
    this.setState({ collaborators: value });
    setTimeout(() => console.log(this.state.collaborators), 1000);
  };

  handleDate = (e) => {
    console.log("date: ", e);
    const year = e._d.getFullYear();
    const month = this.formatHour(e._d.getMonth() + 1);
    const day = this.formatHour(e._d.getDate());
    const hours = String(e._d).substring(16, 24);
    this.setState({
      scheduleDate: year + "-" + month + "-" + day + " " + hours,
    });
  };

  formatHour = (value) => {
    if (String(value).length == 1) {
      return "0" + value;
    }
    return value;
  };

  handleStartTime = (e) => {
    this.setState({
      startTime: String(e._d).substring(16, 21),
    });
  };

  handleFinishTime = (e) => {
    this.setState({
      finishTime: String(e._d).substring(16, 21),
    });
  };

  handleDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  adjustParticipantData = async () => {
    //adjustment in participants.
    for (var i = 0; i < this.state.collaborators.length; ) {
      const uuid_email = this.state.collaborators.pop().split(",");
      console.log(i);
      console.log(this.state.collaborators);
      console.log(uuid_email[0], uuid_email[1]);
      this.collaboratorsUuid.push(uuid_email[0]);
      this.collaboratorsEmail.push(uuid_email[1]);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    await this.adjustParticipantData();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
      console.log(values);
      if (values.timestart.isAfter(values["timeend"])) {
        this.props.form.setFields({
          timeend: {
            value: values.timeend,
            errors: [new Error("Hora de fim menor")],
          },
        });
      }
    });

    try {
      ConferenceAPI.updateConference();
      this.onClose();
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "success",
        "Sucesso.",
        "Agendamendo atualizado com Sucesso."
      );
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } catch (error) {
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "error",
        "Falha ao atualizar o evento agendado.",
        "Houve um problema ao processar a atualização, tente novamente."
      );
    }
  };

  openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      title,
      description,
      users,
      type,
      schedule_date,
      start_time,
      finish_time,
      uuid,
    } = this.props.session;

    return (
      <div className="addDropdown">
        <Drawer
          title="Detalhes da Conferência"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Spin spinning={this.state.loading}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={17}>
                  <Form.Item label="Título">
                    {getFieldDecorator("title", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, adicione o título",
                        },
                      ],
                      initialValue: title,
                    })(
                      <Input
                        ref={(input) => {
                          this.testInput = input;
                        }}
                        placeholder="Adicione o título"
                        onChange={this.handleTitle}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="Tipo da Sessão">
                    <Select
                      defaultValue={type}
                      onChange={this.handleTypeConference}
                    >
                      <Option value="conference">Conferência</Option>
                      <Option value="class">Aula</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Participantes">
                    {getFieldDecorator("participants", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, selecione os Participantes",
                        },
                      ],
                      initialValue: this.state.collaborators,
                    })(
                      <Select
                        notFoundContent={<Empty description={"Sem Dados"} />}
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Selecione os Participantes"
                        onChange={this.handleCollaborators}
                      >
                        {this.children}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Data">
                    {getFieldDecorator("date", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha a data",
                        },
                      ],
                      initialValue: schedule_date,
                    })(
                      <DatePicker
                        size={"default"}
                        placeholder="Selecione a Data"
                        onChange={this.handleDate}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Hora">
                    {getFieldDecorator("timestart", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha o horário de início",
                        },
                      ],
                      initialValue: start_time,
                    })(
                      <TimePicker
                        format={"HH:mm"}
                        placeholder="Início"
                        onChange={this.handleStartTime}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    {getFieldDecorator("timeend", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, escolha o horário de término",
                        },
                      ],
                      initialValue: finish_time,
                    })(
                      <TimePicker
                        format={"HH:mm"}
                        style={{ marginTop: "28px" }}
                        placeholder="Fim"
                        onChange={this.handleFinishTime}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Descrição">
                    {getFieldDecorator("description", {
                      initialValue: description,
                    })(
                      <Input.TextArea
                        rows={4}
                        placeholder="Adicione uma descrição"
                        onChange={this.handleDescription}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div className="typeMsg">
              <h3>Observação</h3>
              {this.state.typeConference == "conference" ? (
                <p>
                  <strong>Conferência: </strong> neste modo os visitantes podem
                  configurar como eles querem entrar na conferência.
                </p>
              ) : (
                <p>
                  <strong>Aula: </strong> Os visitantes entram na conferência
                  com a imagem(câmera) e audio(microfone) desligados.
                </p>
              )}
            </div>
          </Spin>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <div>
              <Button
                onClick={() => this.showDeleteConfirm(uuid)}
                type="danger"
              >
                Apagar
              </Button>
            </div>
            <div>
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancelar
              </Button>
              <Button
                onClick={this.handleSubmit}
                htmlType="submit"
                type="submit"
              >
                Concluir
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
