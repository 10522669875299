import React, { useEffect, useState, useCallback } from "react";
import { Icon } from "antd";
import { ActionsButton } from "./index";
import "./ListRecordedSessions.css";
import { ConferenceAPI } from "../../services";
import { List } from "antd";
import moment from "moment";
import { BtnBrokerPresevation } from "./itens";

export function ListRecordedSessions(props) {
  const [page, updatePage] = useState("");
  console.log("test: ", props.timeInterval);

  function itemRender(current, type, originalElement) {
    console.log(type);
    if (type === "prev") {
      return (
        <button
          className="pagButton"
          style={{ "border-radius": "3px 0px 0px 3px" }}
        >
          <Icon className="iconButton" type="caret-left" theme="filled" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button
          className="pagButton"
          style={{ "border-radius": "0px", "margin-left": "-11px" }}
        >
          <Icon className="iconButton" type="caret-right" theme="filled" />
        </button>
      );
    }
    if (type == "page") {
      return null;
    }
    return null;
  }

  const columns = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Data",
      dataIndex: "schedule_date",
      key: "schedule_date",
    },
    {
      title: "Hora",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "Duração",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Anfitrião",
      dataIndex: "host_id",
      key: "host_id",
    },
    {
      title: "Participantes",
      dataIndex: "users",
      key: "users",
    },
    {
      title: "Status",
      dataIndex: "conference_state.name",
      key: "conference_state.name",
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  function filterData(event) {
    const { timeInterval } = props;
    let date = event.start_time;

    // have some element in the specific date
    if (date >= timeInterval[0])
      if (date <= timeInterval[1]) {
        return true;
      }

    console.log("deu Ruim", event);
    return false;
  }

  function hDescription(description) {
    return description == undefined || description == "" ? "15px" : "42px";
  }

  return (
    <div className="RecordedSessionsContainer">
      <List
        pagination={{
          simple: true,
          position: "top",
          itemRender: itemRender,
          onChange: (page) => {
            updatePage(page);
          },
          pageSize: 7,
        }}
        dataSource={props.data.filter(filterData)}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <div className="listItem">
              <div className="lineContent">
                <div className="line" />
              </div>
              <div className="date">
                <span className="day">{item.schedule_date.format("D")}</span>
                <span className="month">
                  {item.schedule_date.format("MMM")}
                </span>
                <span className="year">{item.schedule_date.format("Y")}</span>
              </div>
              <div className="infoContent" style={{ flex: 1 }}>
                <h4 className="title">{item.title}</h4>
                <span
                  className="description"
                  style={{ height: hDescription(item.description) }}
                >
                  {item.description}
                </span>
                <div style={{ fontSize: "12px" }}>
                  <Icon type="clock-circle" />
                  <span>
                    {` ${item.start_time.format("HH:mm")} `}
                    {item.finish_time
                      ? ` - ${item.finish_time.format("HH:mm")} `
                      : ""}
                  </span>
                  <Icon type="user" />
                  <span> {item.host_id.name} </span>
                </div>
              </div>
              <ActionsButton uuidSession={item.uuid.split("-").join("")} />
            </div>
          </List.Item>
        )}
      />
    </div>
  );
}
