import React from "react";
import { Icon, message } from "antd";
import $ from "jquery";
import Inputmask from "inputmask";
import { ProgressBar } from "../../compoments/common/ProgressBar";
import "./RegisterUser.css";
import api from "../../services/api";
import { RegExpAllowedKeys } from "../../modules/keyboard/map";

//TODO Organizar essa classe e melhorar as validações
export class RegisterUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconColor: "#999",
      iconSize: "16px",
      error: "",
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      phone: "",
      pass: "",
      confirmPass: "",
      companies: [],
    };
  }

  componentDidMount() {
    this.getCompanies();
    var im = new Inputmask("(99) 99999-9999", { jitMasking: true });
    im.mask(document.getElementById("form-input-phone"));
  }

  getCompanies = async () => {
    const { data } = await api.get("/companies");
    this.setState({ companies: data });
    if (data.length > 0) {
      this.setState({ company: data[0].uuid });
    }
  };

  handleClick = (event) => {
    event.preventDefault();
    this.props.modoCadastro();
  };

  validatePassword = () => {
    if (this.state.pass.length >= 6) {
      if (this.state.pass === this.state.confirmPass) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  setError = (element) => {
    $("." + element + "Container").addClass("registerCompanyInputError");
    $("." + element + "Icon").addClass("registerCompanyIconError");
    $("#" + element + "Input").focus();
    this.setState({ error: element });
  };

  clearError = (element) => {
    $("." + element + "Container").removeClass("registerCompanyInputError");
    $("." + element + "Icon").removeClass("registerCompanyIconError");
    this.setState({ error: "" });
  };

  showError = (element) => {
    $("#" + element).css("border-color", "red");
  };

  handlerPassword = (e) => {
    if (RegExpAllowedKeys.test(e.target.value) || e.target.value === "") {
      this.setState({ pass: e.target.value });
    }
  };

  handlerConfirmPassword = (e) => {
    if (RegExpAllowedKeys.test(e.target.value) || e.target.value === "") {
      this.clearError("confirmPass");
      this.setState({ confirmPass: e.target.value });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.clearError();
    console.log(this.state);
    var error = false;

    if (!this.validatePassword()) {
      this.setError("confirmPass");
      error = true;
    }

    if (this.state.firstName == "") {
      this.setError("firstName");
      error = true;
    }

    if (this.state.lastName == "") {
      this.setError("lastName");
      error = true;
    }

    if (this.state.email == "") {
      this.setError("email");
      error = true;
    }

    if (error) {
      return;
    }

    const response = await api.post(
      "/users",
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email,
        password: this.state.pass,
        companyId: this.state.company,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (response.data.registered) {
      document.getElementById("formRegisterUser").reset();
      this.setState({
        error: "",
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        phone: "",
        pass: "",
        confirmPass: "",
      });
      message.success("Cadastro realizado com sucesso!");
      setTimeout(() => {
        this.props.history.push("/");
      }, 1500);
    } else {
      this.setError("email");
    }
  };

  render() {
    return (
      <form
        id="formRegisterUser"
        className="formRegisterUser"
        onSubmit={this.handleSubmit}
      >
        <div className="container firstNameContainer">
          {" "}
          <Icon
            className="firstNameIcon"
            type="user"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="firstNameInput"
            placeholder="Primeiro nome"
            type="text"
            onChange={(e) => {
              this.setState({ firstName: e.target.value });
              this.clearError("firstName");
            }}
            autoFocus
          />
        </div>
        <div className="container lastNameContainer">
          <Icon
            className="lastNameIcon"
            type="user"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="lastNameInput"
            placeholder="Segundo nome"
            type="text"
            onChange={(e) => {
              this.setState({ lastName: e.target.value });
              this.clearError("lastName");
            }}
          />
        </div>
        <div className="container emailContainer">
          <Icon
            className="emailIcon"
            type="mail"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="emailInput"
            className="emailInput"
            placeholder="Email"
            type="email"
            onChange={(e) => {
              this.setState({ email: e.target.value });
              this.clearError("email");
            }}
          />
        </div>
        <div className="container">
          <Icon
            type="phone"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="form-input-phone"
            placeholder="Celular"
            type="tel"
            name="phone"
            pattern="[(]{1}[0-9]{2}[)]{1}[ ]{1}[0-9]{5}-[0-9]{4}"
            title="Ex: (99) 99999-9999"
            onChange={(e) => {
              this.setState({ phone: e.target.value });
            }}
            required
          />
        </div>
        <div className="container">
          <Icon
            type="home"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <select
            value={this.state.company}
            onChange={(e) => {
              this.setState({ company: e.target.value });
            }}
          >
            {this.state.companies.map((company) => (
              <option value={company.uuid}>{company.name}</option>
            ))}
          </select>
        </div>
        <div className="container">
          <Icon
            type="lock"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            placeholder="Senha"
            type="password"
            value={this.state.pass}
            onChange={this.handlerPassword}
          />
        </div>
        <label style={{ color: "#999" }}>
          Sua senha deve ter no mínimo 6 caracteres.
        </label>
        <div className="containerProgressBar">
          <ProgressBar pass={this.state.pass} />
        </div>
        <div className="container confirmPassContainer">
          <Icon
            className="confirmPassIcon"
            type="lock"
            style={{
              fontSize: this.state.iconSize,
              color: this.state.iconColor,
            }}
          />
          <input
            id="confirmPassInput"
            placeholder="Confirme sua Senha"
            type="password"
            value={this.state.confirmPass}
            onChange={this.handlerConfirmPassword}
          />
        </div>

        <div className="registerButton">
          <button type="submit">Cadastre-se</button>
        </div>

        <div className="registerFormBar"></div>

        <div className="registerFormFooter">
          <p>
            Ainda não tem sua organização cadastrada em nosso sistema?{" "}
            <span
              className="registerFormFooterLink"
              onClick={this.handleClick.bind(this)}
            >
              {" "}
              Cadastre sua organização.
            </span>
          </p>
        </div>
      </form>
    );
  }
}
