/* global config */

import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import api from "../../services/api";
import { ResultError } from "../../compoments/resultMsg";
import "./ScheduledSessionConference.css";
require("dotenv/config");

export function ScheduledSessionConference({ props, match, history }) {
  const [error, setError] = useState([]);

  let v4h_ss_domain = `${config.connection.conf_url}/session-service`;

  let coreapi = window.coreapi;
  let schema = window.schema;
  let loggedIn = false;
  let node = {};
  let jApi = {};
  let sessionId = "";
  let token = { access: "", refresh: "" };
  let client = new window.coreapi.Client(v4h_ss_domain);
  let EndedListener;
  let shortLink;

  /** Session Settings **/
  let uuidSession = history.location.state.uuidSession;
  let settingsCam = history.location.state.camStatus;
  let settingsMic = history.location.state.microphoneStatus;
  let typeConference = history.location.state.typeConference;
  let userName =
    localStorage.getItem("v4h_user_firstName") +
    " " +
    localStorage.getItem("v4h_user_lastName");

  useEffect(() => {
    async function HandlerstartVideoConf() {
      try {
        const response = await api.post("/videosession/login", null, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("v4h_token"),
          },
        });
        const tokenJwt = await api.get(
          `/videosession/getLinkAccess/${uuidSession.split("-").join("")}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("v4h_token"),
            },
          }
        );
        shortLink = tokenJwt.data.shortLink;

        token.access = response.data.access;
        token.refresh = response.data.refresh;

        const login = await loginWithToken(token.access);
        console.log("## login ##", uuidSession, login, token.access);

        await startConference(
          uuidSession.split("-").join(""),
          document.querySelector("#meet"),
          "100%",
          "100%"
        );
        document.getElementById("spin").remove();
      } catch (err) {
        console.log("Não conseguiu obter o token {token error!}");
        console.error(err.data);
        setError(<ResultError history={history} />);
      }
    }

    HandlerstartVideoConf();
  }, []);

  function conferenceEnded() {
    document.getElementById("meet").innerHTML = "";
    history.push("/ScheduleAppointment");
  }

  function loginWithToken(access_token) {
    return new Promise(function (resolve, reject) {
      let action = ["api", "token", "verify", "create"];
      let params = { token: access_token };
      client
        .action(schema, action, params)
        .then(function (result) {
          let auth = new coreapi.auth.TokenAuthentication({
            token: access_token,
          });
          client = new coreapi.Client({ auth: auth });
          loggedIn = true;
          token["access"] = access_token;
          resolve(token);
        })
        .catch(function (error) {
          console.error(error);
          loggedIn = false;
          reject(error);
        });
    });
  }

  function startConference(sessionId, parentNode, width, height) {
    return new Promise(function (resolve, reject) {
      node = parentNode;
      let action = ["conferences", "update"];
      let params = { sessionId: sessionId, conf_state: 1 };
      client
        .action(schema, action, params)
        .then(function (result) {
          sessionId = result["sessionId"];
          console.log("testteeee ##", result);
          console.log(
            "V4H-rest-cli-api 1:: starting conf on room " + sessionId
          );
          _openJitsi(sessionId, result["conf_auth"], parentNode, width, height);
          resolve(sessionId);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    });
  }

  function _endedConference() {
    let action = ["conferences", "update"];
    let params = { sessionId: sessionId, conf_state: 3 };
    client.action(schema, action, params).then(function (result) {
      console.log(
        "V4H-rest-cli-api :: updated conf " +
          result["sessionId"] +
          " conf_state to " +
          result["conf_state"]
      );
    });
    conferenceEnded();
  }

  async function _openJitsi(sessionId, jwt, parentNode, width, height) {
    width = width || 640;
    height = height || 480;
    const domain = config.connection.conf_url;
    const options = {
      roomName: sessionId,
      width: width,
      height: height,
      noSsl: false,
      parentNode: node,
      configOverwrite: { autoRecord: true },
      jwt: jwt,
    };

    jApi = new window.JitsiMeetExternalAPI(domain, options);

    /* audio and camera settings of conference */
    jApi.executeCommand("displayName", `${userName}`);
    if (!settingsCam) {
      jApi.executeCommand("toggleVideo");
    }
    if (!settingsMic) {
      jApi.executeCommand("toggleAudio");
    }

    jApi.addEventListener("readyToClose", () => _endedConference());

    /** Link to share **/
    jApi.executeCommand("link", shortLink);
  }

  return (
    <div className="conference-container">
      <div id="meet">
        <div id="spin">
          <Spin size="large" />
        </div>
      </div>
    </div>
  );
}
