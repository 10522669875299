import React from 'react';
import MenuItens from './MenuItens';
import { AvatarUser } from '../menuAccountConfig';

export class MenuV4h extends React.Component {
    state = {
        mode: this.props.mode,
        theme: this.props.menu_theme,
    };

    render() {
        return (
            <div id="menuHorizontal">
                <MenuItens history={this.props.history} mode='horizontal' menu_theme={this.state.theme} />
                <AvatarUser history={this.props.history} mode='bottomLeft'/>
            </div>
        );
    }
}