import React from "react";
import { Avatar, Menu, Dropdown } from "antd";
import "./AvatarUser.css";

export class AvatarUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName:
        localStorage.getItem("v4h_firstName_user") +
        " " +
        localStorage.getItem("v4h_lastName_user"),
      userEmail: localStorage.getItem("v4h_email_user"),
      placement: this.props.mode,
    };
  }

  handleClick = (e) => {
    if (e.key == "/") {
      localStorage.clear();
    }
    if (e.key == "1") {
      return;
    }

    this.props.history.push(`${e.key}`);
  };

  menu = (
    <Menu id="AvatarMenu" onClick={this.handleClick}>
      <Menu.Item key="0" disabled className="header">
        <Avatar
          id="avatarConf"
          size={90}
          style={{ backgroundColor: "gray", fontSize: "40px" }}
        >
          {localStorage.getItem("v4h_user_firstName").substring(0, 1) +
            localStorage.getItem("v4h_user_lastName").substring(0, 1)}
        </Avatar>
        <h3 id="userName">
          {localStorage.getItem("v4h_user_firstName") +
            " " +
            localStorage.getItem("v4h_user_lastName")}
        </h3>
        <p id="userEmail">{localStorage.getItem("v4h_user_email")}</p>
      </Menu.Item>
      <Menu.Item key="/MyAccount">Minha Conta</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="/">Sair</Menu.Item>
    </Menu>
  );

  render() {
    return (
      <Dropdown
        overlay={this.menu}
        trigger={["click"]}
        placement={this.state.placement}
      >
        <Avatar id="avatar" size={40} style={{ backgroundColor: "gray" }}>
          {localStorage.getItem("v4h_user_firstName").substring(0, 1) +
            localStorage.getItem("v4h_user_lastName").substring(0, 1)}
        </Avatar>
      </Dropdown>
    );
  }
}
