import React, { useState } from "react";
import "./ForgetPassword.css";
import { UserAPI } from "../../services";
import { ButtonBack } from "../../compoments/common/buttonBack";
import { Spin, Modal, Button } from "antd";

export function ForgetPassword({ history }) {
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const { error } = await UserAPI.forgotPassword(email);
    setloading(false);

    if (error) return;

    showModalSucess();
  };

  const showModalSucess = () => {
    Modal.success({
      content:
        "Se seu e-mail estiver cadastrado você deverá receber um e-mail nos próximos minutos. Por favor verifique sua caixa de mensagens.",
      onOk: () => history.push("/"),
    });
  };

  return (
    <div id="containerForgetPassword">
      <ButtonBack history={history} go="/" />

      <div className="title">Redefinição de senha</div>

      <p className="description">
        Insira o e-mail que você usou no cadastro. Nós mandaremos um e-mail
        <br />
        com seu nome de usuário e um link para você redefinir sua senha.
      </p>
      <form onSubmit={handlerSubmit}>
        <input
          placeholder="Insira seu Email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
        />
        <Spin spinning={loading}>
          <button type="submit" className="btnSubmit">
            Enviar
          </button>
        </Spin>
      </form>
    </div>
  );
}
