import React from "react";
import "./ResultSucess.css";
import { Result, Button } from "antd";

export function ResultSucess({ history }) {
  const { title, subTitle, goTo } = history.location.state;

  onkeypress = (e) => {
    if (e.keyCode === 13) {
      onkeypress = () => {};
      history.push(goTo);
    }
  };

  return (
    <div id="containerDeleteAccount">
      <Result
        status="success"
        title={title}
        subTitle={subTitle}
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => history.push(goTo)}
            htmlType="submit"
          >
            {goTo !== "/" ? "voltar" : "Ir para página principal"}
          </Button>,
        ]}
      />
    </div>
  );
}
