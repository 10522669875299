export const mapAllowedKeys = {
  33: "!",
  35: "#",
  36: "$",
  37: "%",
  38: "&",
  40: "(",
  41: ")",
  42: "*",
  43: "+",
  44: ",",
  // 45: "-",
  46: ".",
  47: "/",
  58: ":",
  59: ";",
  60: "<",
  61: "=",
  62: ">",
  63: "?",
  64: "@",
  91: "[",
  92: "\\",
  // 93: "]",
  95: "_",
  123: "{",
  124: "|",
  125: "}",
};

export const allowedKeys = Object.values(mapAllowedKeys);

export const RegExpAllowedKeys = new RegExp(
  `^[0-9a-zA-Z${allowedKeys.join("")}]{1,}$`
);
